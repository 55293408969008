//https://fontawesome.com/docs/web/use-with/react/
//https://fontawesome.com/icons
//yarn add @fortawesome/fontawesome-svg-core
//yarn add @fortawesome/free-solid-svg-icons
//yarn add @fortawesome/free-regular-svg-icons
//yarn add @fortawesome/react-fontawesome@latest


import React, { useContext } from "react";
import { useState } from 'react';

import '../AboutButton/AboutButton.css'

const itensCheckbox = [
    {
        id: 'area-especial',
        label: 'Projeto de Recadastramento Imobiliário',
    }
];

function AboutButton() {
    const [showList, setShowList] = useState(false);
    function handleButtonClick() {
        setShowList(!showList);
    }


    return (
        <div className="about-button-About">
            <button className="camada-btn-About" onClick={handleButtonClick}>Sobre
            </button>
            {showList && (
                <div className="float-container-About">
                    {itensCheckbox.map((item) => (
                        <ul key={item.id}>
                            <label htmlFor={item.id} className="toggle-About">
                                <span className="toggle-label-About">{item.label}</span>
                            </label>
                        </ul>
                    ))}
                </div>
            )}
        </div>
    )
}

export default AboutButton;

