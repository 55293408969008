import React, { useState, useEffect, useRef } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { Card, FormControl, FormGroup, FormControlLabel, Paper, Switch, Tabs, Tab, Radio, RadioGroup, FormLabel, TextField, Button, Divider, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import GeoJSON from 'ol/format/GeoJSON.js';
import Map from 'ol/Map.js';
import View from 'ol/View.js';
import { Fill, Stroke, Style } from 'ol/style.js';
import { OSM, Vector as VectorSource } from 'ol/source.js';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer.js';
import proj4 from "proj4";

import { Api } from "../../../services/Api.js";

import "./ModalRemembramento.css";

const ModalRemembramento = ({ onClose, idAdversidade }) => {
  const [filteredData, setFilteredData] = useState("");

  const [selectedValue, setSelectedValue] = useState("");
  const [selectedValueCodigo, setSelectedValueCodigo] = useState("");
  const [valueTabs, setValueTabs] = useState(0);
  const [valueRadioGroup, setValueRadioGroup] = useState('');

  const [isTabDisabled, setIsTabDisabled] = useState(true); //habilitar  e desabilidar o tab

  const [currentZoom, setCurrentZoom] = useState(5);


  const handleSubmit = (e) => {

  };

  const onCloseClick = () => {
    onClose();
  };

  //Para a chamada da função para sair
  const onModalClick = (e) => {
    e.stopPropagation();
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await Api.getAdversidadesId(idAdversidade)
        // Remover elementos com nome nulo
        const filteredData = data;
        console.log(filteredData)
        setFilteredData(filteredData)
      } catch (error) {
        console.log('Deu erro:', error);
      }
    };

    fetchData();
  }, []);

  const [state, setState] = useState(false);

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };



  const handleChangeTabs = (event, newValue) => {
    setValueTabs(newValue);
  };


  const handleChangeRadioGroup = (event) => {
    if (event.target.value === 'casoRemembramento') {
      setValueTabs(1);
      setIsTabDisabled(false);
    }
    else {
      setIsTabDisabled(true);
      setValueTabs(0); // Define o valor da aba como 0 para "Mapa"
    }
    if (valueRadioGroup === event.target.value) {
      setValueTabs(0);
      setIsTabDisabled(true);
      setValueRadioGroup('casoNãoRemembramento');
    }
    else
      setValueRadioGroup(event.target.value);
  };


  //Configura a lista de acordo com o tamanho da tela 
  // useEffect(() => {
  //   if (window.innerHeight > 750) {
  //     const numberPerPage = 12;
  //     setRowsPerPage(numberPerPage)
  //   }
  // }, [])


  const styles = {
    'MultiPolygon': new Style({
      stroke: new Stroke({
        color: 'blue',
        width: 1,
      }),
      fill: new Fill({
        color: 'rgba(255, 255, 0, 0.1)',
      }),
    }),
  };

  const styleFunction = function (feature) {
    return styles[feature.getGeometry().getType()];
  };

  const [coordenadasPoligono, setCoordenadasPoligono] = useState([
    [507511.096510162, 7568780.598330974],
    [507518.823996995, 7568788.760727163],
    [507523.718691678, 7568797.971094619],
    [507530.018919432, 7568793.229685036],
    [507528.599032377, 7568789.29975676],
    [507529.417528115, 7568786.891102029],
    [507525.102074188, 7568783.64620372],
    [507521.015141249, 7568778.592657473],
    [507512.897200136, 7568775.259393719],
    [507511.096510162, 7568780.598330974]
  ]);

  useEffect(() => () => {
    configurarCoordenadaCentral();
    configurarCoordenada(coordenadasPoligono)
  }, []);

  const configurarCoordenada = (coordenadasPoligono) => {

    const utmProjection = '+proj=utm +zone=23 +south +ellps=WGS84 +datum=WGS84 +units=m +no_defs';
    const latLngProjection = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';

    const latLngCoordinatesArray = coordenadasPoligono.map((coordenadas) => {
      return proj4(utmProjection, latLngProjection, coordenadas);
    });
    setCoordenadasPoligono(latLngCoordinatesArray);
  };

  const geojsonObject = {
    'type': 'FeatureCollection',
    'crs': {
      'type': 'name',
      'properties': {
        'name': 'EPSG:4326',
      },
    },
    'features': [
      {
        'type': 'Feature',
        'geometry': {
          'type': 'MultiPolygon',
          'coordinates': [
            [
              coordenadasPoligono
            ],
          ],
        },
      },
    ],
  };

  const vectorSource = new VectorSource({
    features: new GeoJSON().readFeatures(geojsonObject),
  });


  const vectorLayer = new VectorLayer({
    source: vectorSource,
    style: styleFunction,
  });

  const mapRef = useRef(null);
  const [coordenadas, setCoordenadas] = useState([]);

  const configurarCoordenadaCentral = () => {
    const utmProjection = '+proj=utm +zone=23 +south +ellps=WGS84 +datum=WGS84 +units=m +no_defs';
    const latLngProjection = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';

    const coordenadasLimpo = [507520.195861565, 7568784.270602539];
    const latLngCoordinates = proj4(utmProjection, latLngProjection, coordenadasLimpo);
    setCoordenadas(latLngCoordinates);
  }


  useEffect(() => {
    const newMap = new Map({
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        vectorLayer,
      ],
      view: new View({
        center: [coordenadas[0], coordenadas[1]],
        zoom: 19,
        projection: 'EPSG:4326',
      }),
    });
    return () => {
      newMap.dispose();
    };
  }, [coordenadas, currentZoom, valueTabs]);


  return (
    <div className="ModalRemembramento-modal" onClick={onCloseClick}>
      <div style={{ display: "flex", flexDirection: 'column' }}>
        <div className="ModalRemembramento-modal-content" onClick={onModalClick}>
          <div className="ModalRemembramento-close-bnt" >
            <Button aria-label="Fechar" onClick={onCloseClick} >
              <CloseIcon />
            </Button>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>



            {/* Coluna 1 */}
            <Card variant="outlined">
              <div style={{ margin: '20px' }}>

                <h5 style={{ textAlign: "start", marginBottom: "10px" }}>DADO DO LOTE REMEBRADO:</h5>
                <form onSubmit={handleSubmit}>

                  <div className="ModalRemembramento-form-row">
                    <div className="ModalRemembramento-form-group">
                      <label htmlFor="setor" className="ModalRemembramento-label">
                        Setor:
                      </label>
                      <input
                        className="ModalRemembramento-fixed"
                        id="Setor"
                        value={filteredData.setor}
                        onChange={(e) => setSelectedValue(e.target.value)}
                        required
                        readOnly
                      />
                    </div>

                    <div className="ModalRemembramento-form-group">
                      <label htmlFor="quadra" className="ModalRemembramento-label">
                        Quadra:
                      </label>
                      <input
                        className="ModalRemembramento-fixed"
                        id="Quadra"
                        value={filteredData.quadra}
                        required
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="ModalRemembramento-form-group">
                    <label htmlFor="quadra" className="ModalRemembramento-label">
                      *Os números de inscrição reduzidos dos lotes remembrandos são:
                    </label>
                    <input
                      className="ModalRemembramento-fixed"
                      id="Quadra"
                      value={filteredData.ids}
                      required
                      readOnly
                    />
                  </div>

                  <FormControl style={{ marginTop: '25px' }}>
                    <FormLabel id="demo-controlled-radio-buttons-group">Escolha a opção que melhor se aplica a situação do lote.</FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={valueRadioGroup}
                      onClick={handleChangeRadioGroup}
                    >
                      <FormControlLabel value="casoRemembramento" control={<Radio />} label="SIM, é um caso de remembramento" />
                      <FormControlLabel value="casoNaoRemembramento" control={<Radio />} label="NÃO, não é um caso de remembramento" />
                    </RadioGroup>
                  </FormControl>

                  <TextField style={{ width: '100%', marginTop: '25px' }}
                    id="outlined-multiline-static"
                    label="Observações"
                    multiline
                    rows={8}
                    defaultValue=""
                  />
                </form>
              </div>
            </Card>





            {/* colunas 2 */}

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }} >
              <Card variant="outlined">
                <Paper style={{ width: '100%', marginBottom: '15px' }} variant="outlined" square elevation={3} >
                  <Tabs value={valueTabs} onChange={handleChangeTabs} centered>
                    <Tab label="Mapa" />
                    <Tab label="Dados do Lote" disabled={isTabDisabled} />
                  </Tabs>

                </Paper>

                <div style={{ width: '600px', height: '525px' }}>

                  {/* MAPA */}
                  {!valueTabs ?
                    <div >
                      <h5 style={{ marginLeft: '10px' }}>GEOMETRIAS IDENTIFICADA NA VETORIZAÇÃO:</h5>
                      <FormControl component="fieldset" variant="standard">
                        <FormGroup style={{ display: 'flex', flexDirection: 'row', margin: '10px' }}>
                          <FormControlLabel
                            control={<Switch checked={state.gilad} onChange={handleChange} name="coberturas" />}
                            label="Mostrar coberturas" />
                          <FormControlLabel
                            control={<Switch checked={state.jason} onChange={handleChange} name="lotes" />}
                            label="Mostrar lotes" />
                          <FormControlLabel
                            control={<Switch checked={state.antoine} onChange={handleChange} name="lotesVizinhos" />}
                            label="Mostrar lotes vizinhos" />
                        </FormGroup>
                      </FormControl>
                      <Box
                        sx={{
                          display: 'flex',
                          position: 'fixed',
                          margin: 0.7,
                          marginLeft: 5,
                          zIndex: 1,
                          alignItems: 'center',
                          width: 'fit-content',
                          border: (theme) => `2px solid ${theme.palette.divider}`,
                          borderRadius: 1,
                          bgcolor: 'background.paper',
                          color: 'text.secondary', '& svg': { m: 0.5, }, '& hr': { mx: 0.5, },
                        }}
                      >
                        <div style={{ background: '#e57373', height: '20px', width: '20px', margin: '15px' }} ></div>
                        <h5 style={{ margin: '15px', marginLeft: '0px' }}>Lote</h5>
                        <Divider orientation="vertical" flexItem />

                        <div style={{ background: '#90caf9', height: '20px', width: '20px', margin: '15px' }} ></div>
                        <h5 style={{ margin: '15px', marginLeft: '0px' }}>Lotes vizinhos</h5>
                      </Box>
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flex: "1", backgroundColor: "#AAD3DF" }}>
                        <div ref={mapRef} style={{ width: '100%', height: '550px' }} />
                      </div>
                    </div> :

                    <div style={{ width: '600px' }}>

                      {/* ÁREA DE INPUTS DADOS DO LOTE */}
                      <h5 style={{ marginLeft: '10px', marginBottom: '20px' }}>DADOS DO LOTE:</h5>

                      <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                        <form onSubmit={handleSubmit}>

                          <div className="ModalRemembramento-form-row">
                            <div className="ModalRemembramento-form-group">
                              <label htmlFor="codigo" className="ModalRemembramento-label">
                                Código:
                              </label>
                              <input
                                className="ModalRemembramento-input"
                                type="text"
                                id="Codigo"
                                value={selectedValueCodigo}
                                onChange={(e) => setSelectedValueCodigo(e.target.value)}
                                required
                              />
                            </div>

                            <div className="ModalRemembramento-form-group">
                              <label htmlFor="selector" className="ModalRemembramento-label">
                                Lote:
                              </label>
                              <select
                                className="ModalRemembramento-select"
                                id="select"
                                // value={postesIluminacao}
                                // onChange={(e) => setPostesIluminacao(e.target.value)}
                                required
                              >
                                <option value="">Selecione:</option>
                                {filteredData.lotes?.map(
                                  (row, index) => (
                                    <option value="" key={index}> {row} </option>
                                  )
                                )}


                              </select>
                            </div>

                            <div className="ModalRemembramento-form-group">
                              <label htmlFor="setor" className="ModalRemembramento-label">
                                Setor:
                              </label>
                              <input
                                className="ModalRemembramento-fixed"
                                id="Setor"
                                value={filteredData.setor}
                                onChange={(e) => setSelectedValue(e.target.value)}
                                required
                                readOnly
                              />
                            </div>

                            <div className="ModalRemembramento-form-group">
                              <label htmlFor="quadra" className="ModalRemembramento-label">
                                Quadra:
                              </label>
                              <input
                                className="ModalRemembramento-fixed"
                                id="Quadra"
                                value={filteredData.quadra}
                                required
                                readOnly
                              />
                            </div>

                          </div>
                        </form>
                      </div>
                      <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                        <Button style={{ width: '50%', marginTop: '10px', marginBottom: '15px' }} variant="contained" >
                          <FontAwesomeIcon icon={faMagnifyingGlass} style={{ marginRight: '0.5em' }} />Consultar Lote
                        </Button>
                      </div>


                      <div></div>

                      <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                        <div className="ModalRemembramento-form-row">
                          <div style={{ flex: '0.4' }} className="ModalRemembramento-form-group">
                            <label htmlFor="codigo" className="ModalRemembramento-label">
                              Nº de Unidade(s):
                            </label>
                            <input
                              className="ModalRemembramento-input"
                              type="text"
                              id="Codigo"
                              value={selectedValueCodigo}
                              onChange={(e) => setSelectedValueCodigo(e.target.value)}
                              required
                            />
                          </div>

                          <div style={{ flex: '1' }} className="ModalRemembramento-form-group">
                            <label htmlFor="selector" className="ModalRemembramento-label">
                              Logradouro:
                            </label>
                            <select
                              className="ModalRemembramento-select"
                              id="select"
                              // value={postesIluminacao}
                              // onChange={(e) => setPostesIluminacao(e.target.value)}
                              required
                            >
                              <option value="">Selecione:</option>
                              {filteredData.logradouros?.map(
                                (row, index) => (
                                  <option value="" key={index}> {row} </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                        <div className="ModalRemembramento-form-row">
                          <div style={{ flex: '0.4' }} className="ModalRemembramento-form-group">
                            <label htmlFor="codigo" className="ModalRemembramento-label">
                              Número:
                            </label>
                            <input
                              className="ModalRemembramento-input"
                              type="text"
                              id="Codigo"
                              value={selectedValueCodigo}
                              onChange={(e) => setSelectedValueCodigo(e.target.value)}
                              required
                            />
                          </div>

                          <div style={{ flex: '1' }} className="ModalRemembramento-form-group">
                            <label htmlFor="selector" className="ModalRemembramento-label">
                              Bairro:
                            </label>
                            <input
                              className="ModalRemembramento-input"
                              type="text"
                              id="Codigo"
                              value={selectedValueCodigo}
                              onChange={(e) => setSelectedValueCodigo(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                      </div>

                      <Divider style={{ marginTop: '30px', marginBottom: '20px', marginLeft: '10px', marginRight: '10px' }} />

                      <h5 style={{ marginLeft: '10px', marginBottom: '20px' }}>DADOS DO PROPRIETÁRIO:</h5>
                      <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                        <div className="ModalRemembramento-form-row">
                          <div style={{ flex: '0.4' }} className="ModalRemembramento-form-group">
                            <label htmlFor="selector" className="ModalRemembramento-label">
                              Tipo:
                            </label>
                            <select
                              className="ModalRemembramento-select"
                              id="select"
                              // value={postesIluminacao}
                              // onChange={(e) => setPostesIluminacao(e.target.value)}
                              required
                            >
                              <option value="">Selecione:</option>
                              <option value="">Pessoa Física</option>
                              <option value="">Pessoa Jurídica</option>
                            </select>
                          </div>

                          <div style={{ flex: '1' }} className="ModalRemembramento-form-group">
                            <label htmlFor="codigo" className="ModalRemembramento-label">
                              CPF/CNPJ:
                            </label>
                            <input
                              className="ModalRemembramento-input"
                              type="text"
                              id="Codigo"
                              value={selectedValueCodigo}
                              onChange={(e) => setSelectedValueCodigo(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                      </div>

                      <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                        <Button style={{ width: '50%', marginTop: '10px' }} variant="contained" >
                          <FontAwesomeIcon icon={faMagnifyingGlass} style={{ marginRight: '0.5em' }} />Consultar Proprietário
                        </Button>
                      </div>

                    </div>


                  }
                </div>


              </Card>
            </div>
          </div>


          <div className="ModalRemembramento-form-row">
            <Button style={{ width: '100%' }} variant="contained" >
              <FontAwesomeIcon icon={faFloppyDisk} style={{ marginRight: '0.5em' }} />Salvar
            </Button>
          </div>
        </div>
      </div>
    </div >
  );
};

export default ModalRemembramento;
