import "./App.css";

import { useEffect, useState, useContext } from "react";

import Header from "./Components/Header/Header";
import MapRender from "./Components/MapRender/MapRender";
import TelaLogin from "./Components/TelaLogin/TelaLogin";
import AboutButton from "./Components/AboutButton/AboutButton";
import FloatingButton from "./Components/FloatingButton/FloatingButton";
import { ContextMapProvider, ContextMap } from "./ContextMap/ContextMap";
import Conteudo from "./Components/Conteudo/Conteudo";

import satelite from './assets/img/Satelite.png';
import carto from './assets/img/openstreet.png';
import orto from './assets/img/orto.png';
import google from './assets/img/logo_google.png';



function App() {
  const [openTelaLogin, setOpenTelaLogin] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [estadoLogin, setEstadoLogin] = useState("");

  function toggletelaLogin() {
    setOpenTelaLogin(!openTelaLogin);
  }

  function toggletelaSidebar() {
    setShowSidebar(!showSidebar);
  }

  

  function nullEstadoLogin() {

    setEstadoLogin(sessionStorage.setItem("infoUsuarioToken", ""))
    setEstadoLogin(sessionStorage.getItem("infoUsuarioToken"))
  }

  function toggleteEstadoLogin() {

    setEstadoLogin(sessionStorage.getItem("infoUsuarioToken"))
  }

  
  useEffect(() => {

    toggleteEstadoLogin();
  }, [estadoLogin])



  function MeuComponente() {
    const { alterarTipoMapa, adicionarObjeto, removerObjeto, tipoMapa } = useContext(ContextMap);

    return (
      <>
        <div className="row-button-satelite">
          <div className="div-button-satelite" onClick={() => { alterarTipoMapa(false) }}>
            <img className="botao" src={satelite} alt="satélite" />
            <label className="label-satelite" htmlFor="satélite">
              Satélite
            </label>
          </div>

          <div className="div-button-satelite" onClick={() => {
            alterarTipoMapa(true)
            removerObjeto('Ortofoto')
          }}>
            <img className="botao" src={carto} alt="carto" />
            <label className="label-satelite" htmlFor="carto">
              Carto
            </label>
          </div>

          <div className="div-button-satelite" onClick={() => {
            adicionarObjeto({ id: 'Ortofoto', label: 'Ortofoto', url: 'SaoLourenco', layer: 'SaoLourenco:satelinovo', ativo: true })
          }}>
            <img className="botao" src={orto} alt="orto" style={{ marginRight: '0.6vw' }} />
            <label className="label-satelite" htmlFor="orto">
              Orto
            </label>
          </div>

        </div>
        {tipoMapa ? null :
          (
            <>
              < img style={{ width: '3rem', bottom: 0, position: 'fixed', cursor: 'default' }} src={google} />
              <a href="https://www.openstreetmap.org/copyright" className="logo-OSM" target="_blank">
                © OpenStreetMap contributors.
              </a>
            </>
          )}

      </>
    );
  }

  return (
    <div className="App">
      <ContextMapProvider>
        <div className='floating-button-mapa' style={{ position: "absolute", marginLeft: (!showSidebar && estadoLogin) ? '250px' : '0px', transition: 'margin-left 1s ease' }}>
          <MeuComponente />
        </div>
        <Header setOpenTelaLogin={toggletelaLogin} toggletelaSidebar={toggletelaSidebar} estadoLogin={estadoLogin} nullEstadoLogin={nullEstadoLogin} />
        {!openTelaLogin && <div style={{ marginLeft: (!showSidebar && estadoLogin) ? '250px' : '0px', transition: 'margin-left 1s ease' }}> <Conteudo estadoLogin={estadoLogin} /> </div>}

        {!openTelaLogin && !estadoLogin && <div className="container-AboutButton" ><AboutButton /></div>}
        <TelaLogin isOpen={openTelaLogin} setOpenTelaLogin={toggletelaLogin} toggleteEstadoLogin={toggleteEstadoLogin} />
      </ContextMapProvider>
    </div>
  );
}

export default App;