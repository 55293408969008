import React, { useState, useRef, useEffect } from "react";
import { Api } from "../../services/Api.js";
import { faEdit, faMagnifyingGlass, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Tabs, Tab, Box, TableCell, TableRow, TextField, Paper, Table, TableContainer, TableHead, TableBody, Fab, Button } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; import GeoJSON from 'ol/format/GeoJSON.js';
import CloseIcon from '@mui/icons-material/Close';
import Map from 'ol/Map.js';
import View from 'ol/View.js';
import { Fill, Stroke, Style } from 'ol/style.js';
import { OSM, Vector as VectorSource } from 'ol/source.js';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer.js';
import proj4 from "proj4";
import { click, pointerMove } from 'ol/events/condition';
import Select from 'ol/interaction/Select';

import "./GerenciarColetas.css";



const GerenciarColetas = () => {

  const [value, setValue] = React.useState(0);
  const [searching, setSearching] = useState(false);
  const [legenda, setLegenda] = useState("Principal");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  async function submit() {

    setSearching(true);

    setSearching(false);


  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const mapRef = useRef(null);
  const selectInteractionRef = useRef(null);
  const [currentZoom, setCurrentZoom] = useState(5);


  //useState coordenadas
  const [coordenadas, setCoordenadas] = useState([-44.0524, -18.4833]);

  //Configura a lista de acordo com o tamanho da tela 
  // useEffect(() => {
  //   if (window.innerHeight > 750) {
  //     const numberPerPage = 12;
  //     setRowsPerPage(numberPerPage)
  //   }
  // }, [])

  const styles = {
    'MultiPolygon': new Style({
      stroke: new Stroke({
        color: 'blue',
        width: 1,
      }),
      fill: new Fill({
        color: 'rgba(255, 255, 0, 0.1)',
      }),
    }),
  };

  const styleFunction = function (feature) {
    return styles[feature.getGeometry().getType()];
  };

  const [coordenadasPoligono, setCoordenadasPoligono] = useState([
    [507511.096510162, 7568780.598330974],
    [507518.823996995, 7568788.760727163],
    [507523.718691678, 7568797.971094619],
    [507530.018919432, 7568793.229685036],
    [507528.599032377, 7568789.29975676],
    [507529.417528115, 7568786.891102029],
    [507525.102074188, 7568783.64620372],
    [507521.015141249, 7568778.592657473],
    [507512.897200136, 7568775.259393719],
    [507511.096510162, 7568780.598330974]
  ]);

  useEffect(() => () => {
    configurarCoordenadaCentral();
    configurarCoordenada(coordenadasPoligono)
  }, []);

  const configurarCoordenada = (coordenadasPoligono) => {

    const utmProjection = '+proj=utm +zone=23 +south +ellps=WGS84 +datum=WGS84 +units=m +no_defs';
    const latLngProjection = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';

    const latLngCoordinatesArray = coordenadasPoligono.map((coordenadas) => {
      return proj4(utmProjection, latLngProjection, coordenadas);
    });
    setCoordenadasPoligono(latLngCoordinatesArray);
  };

  const geojsonObject = {
    'type': 'FeatureCollection',
    'crs': {
      'type': 'name',
      'properties': {
        'name': 'EPSG:4326',
      },
    },
    'features': [
      {
        'type': 'Feature',
        'geometry': {
          'type': 'MultiPolygon',
          'coordinates': [
            [
              coordenadasPoligono
            ],
          ],
        },
      },
    ],
  };

  const vectorSource = new VectorSource({
    features: new GeoJSON().readFeatures(geojsonObject),
  });

  const vectorLayer = new VectorLayer({
    source: vectorSource,
    style: styleFunction,
  });

  const configurarCoordenadaCentral = () => {
    const utmProjection = '+proj=utm +zone=23 +south +ellps=WGS84 +datum=WGS84 +units=m +no_defs';
    const latLngProjection = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';

    const coordenadasPoligonoCentral = [507520.195861565, 7568784.270602539];
    const latLngCoordinates = proj4(utmProjection, latLngProjection, coordenadasPoligonoCentral);
    setCoordenadas(latLngCoordinates);
  }
  const [isHovering, setIsHovering] = useState(false); //Controle do hover do poligonal

  useEffect(() => {
    
    //Efeito de Click no Poligono
    const clickInteraction = new Select({
      condition: click,
    });
    clickInteraction.on('select', (event) => {
      if (event.selected.length > 0) {
        setLegenda("Completadas")
        setTabelaQuadra(true);
      }
    });
    
    //Efeito de Localizar posição do Mouse
    const hoverInteraction = new Select({
      condition: pointerMove,
    });
    hoverInteraction.on('select', (event) => {
      if (event.selected.length > 0) {
        setIsHovering(true);
      } else {
        setIsHovering(false);
      }
    });
    
    selectInteractionRef.current = clickInteraction;

    const newMap = new Map({
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        vectorLayer,
      ],
      view: new View({
        center: [coordenadas[0], coordenadas[1]],
        zoom: 19,
        projection: 'EPSG:4326',
      }),
    });

    //Adciona os eventos de interação
    newMap.addInteraction(selectInteractionRef.current);
    newMap.addInteraction(hoverInteraction);

    return () => {
      newMap.removeInteraction(selectInteractionRef.current);
      newMap.dispose();
    };
  }, [coordenadas, currentZoom, value]);


  // tabelas
  const [tabelaQuadra, setTabelaQuadra] = useState(true);
  function closeLegenda() {
    // Desmarca as features atualmente selecionadas
    selectInteractionRef.current.getFeatures().clear();

    setLegenda("Principal");
    setTabelaQuadra(true);
  }

  function closeTabelaQuadra() {
    selectInteractionRef.current.getFeatures().clear();
    setTabelaQuadra(!tabelaQuadra)
  }


  return (
    <div style={{ height: '80.4vh' }}>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Geral" {...a11yProps(0)} />
            <Tab label="Coletores" {...a11yProps(1)} />
          </Tabs>
        </Box>
      </Box>

      {/* Tela Mapa */}
      {(value === 0) &&
        <div style={{ margin: 20, marginTop: 40, position: "relative" }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              position: 'absolute',
              left: 8,
              top: 8,
              zIndex: 1,
              width: 'fit-content',
              border: (theme) => `2px solid ${theme.palette.divider}`,
              borderRadius: 1,
              bgcolor: 'background.paper',
              color: 'text.secondary', '& svg': { m: 0.5, }, '& hr': { mx: 0.5, },
            }}
          >
            {/* Legenda Principal */}
            {(legenda === "Principal") &&
              <div>
                <h5 style={{ marginLeft: 5, padding: 5 }}>QUADRAS E EIXO</h5>
                <div style={{ display: 'flex', padding: 5, paddingTop: 2 }}>
                  <div style={{ background: '#90faa9', height: '20px', width: '20px', margin: '5px' }} ></div>
                  <h5 style={{ margin: '5px', marginLeft: '0px', width: '100px' }}>Disponíveis</h5>
                </div>

                <div style={{ display: 'flex', padding: 5, paddingTop: 2 }}>
                  <div style={{ background: '#90caf9', height: '20px', width: '20px', margin: '5px' }} ></div>
                  <h5 style={{ margin: '5px', marginLeft: '0px', width: '100px' }}>Selecionadas(os)</h5>
                </div>

                <div style={{ display: 'flex', padding: 5, paddingTop: 2 }}>
                  <div style={{ background: '#e1f273', height: '20px', width: '20px', margin: '5px' }} ></div>
                  <h5 style={{ margin: '5px', marginLeft: '0px', width: '100px' }}>Coletadas(os)</h5>
                </div>

                <div style={{ display: 'flex', padding: 5, paddingTop: 2 }}>
                  <div style={{ background: '#e57373', height: '20px', width: '20px', margin: '5px' }} ></div>
                  <h5 style={{ margin: '5px', marginLeft: '0px', width: '100px' }}>Incompletas</h5>
                </div>
              </div>
            }

            {/* Legenda Selecionadas */}
            {(legenda === "Completadas") &&
              <div>
                <div style={{ marginBottom: 45, width: 150 }}>
                  <div className="close-bnt-GerenciarColetas">
                    <Button onClick={closeLegenda}
                      aria-label="Voltar"
                      style={{ padding: 5, minWidth: 0 }}
                    >
                      <ArrowBackIcon />
                      Voltar
                    </Button>
                  </div>
                </div>
                <h5 style={{ marginLeft: 5, padding: 5 }}>LOTES</h5>

                <div style={{ display: 'flex', padding: 5, paddingTop: 2 }}>
                  <div style={{ background: '#90caf9', height: '20px', width: '20px', margin: '5px' }} ></div>
                  <h5 style={{ margin: '5px', marginLeft: '0px', width: 'auto' }}>Não Coletadas(os)</h5>
                </div>

                <div style={{ display: 'flex', padding: 5, paddingTop: 2 }}>
                  <div style={{ background: '#e1f273', height: '20px', width: '20px', margin: '5px' }} ></div>
                  <h5 style={{ margin: '5px', marginLeft: '0px', width: 'auto' }}>Coletadas(os)</h5>
                </div>

                <div style={{ display: 'flex', padding: 5, paddingTop: 2 }}>
                  <div style={{ background: '#e57373', height: '20px', width: '20px', margin: '5px' }} ></div>
                  <h5 style={{ margin: '5px', marginLeft: '0px', width: 'auto' }}>Incompletas</h5>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 15 }}>
                  <Button
                    style={{ width: "100%", fontSize: "0.8em" }}
                    variant="contained"
                  >
                    <FontAwesomeIcon
                      icon={faEdit}
                      style={{ fontSize: "1.2em", marginRight: "12px" }}
                    />
                    Alocar Quadra
                  </Button>
                  <Button
                    style={{ width: "100%", fontSize: "0.8em", marginTop: 15 }}
                    variant="contained"
                  >
                    <FontAwesomeIcon
                      icon={faEdit}
                      style={{ fontSize: "1.2em", marginRight: "12px" }}
                    />
                    Deslocar Quadra
                  </Button>
                </div>
              </div>
            }
          </Box>

          {/* Tabela Quadra */}
          {((legenda === "Completadas") && (tabelaQuadra == true)) &&
            <div>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  zIndex: 1,
                  width: 'fit-content',
                  border: (theme) => `2px solid ${theme.palette.divider}`,
                  borderRadius: 3,
                  padding: 1,
                  bgcolor: 'background.paper',
                  color: 'text.secondary', '& svg': { m: 0.5, }, '& hr': { mx: 0.5, },
                }}
              >
                <div style={{ marginBottom: 20, width: 200, position: 'relative' }}>
                  <div className="close-bnt-TabelaQuadra-GerenciarColetas" >
                    <Button style={{ height: "25px", minWidth: '5px', padding: 0 }} aria-label="Fechar" onClick={closeTabelaQuadra}>
                      <CloseIcon style={{ fontSize: "1rem" }} />
                    </Button>
                  </div>
                </div>
                <h5 style={{ marginLeft: 5, padding: 5 }}>Código da quadra:</h5>
                <h5 style={{ marginLeft: 5, padding: 5 }}>Setor da quadra:</h5>
                <h5 style={{ marginLeft: 5, padding: 5 }}>ID da quadra:</h5>
                <h5 style={{ marginLeft: 5, padding: 5 }}>Coletor responsável:</h5>
                <h5 style={{ marginLeft: 5, padding: 5 }}>Quantidade de lotes:</h5>
              </Box>
            </div>
          }


          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flex: "1", backgroundColor: "#AAD3DF" }}>
            <div ref={mapRef} style={{ width: '100%', height: '550px', cursor: isHovering ? 'pointer' : 'auto' }} />
          </div>
        </div >
      }

      {/* Tela Coletores */}
      {
        (value === 1) &&
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="container-GerenciarColetas" style={{ padding: 10, width: '100%' }}>
            <div className="conteudo-GerenciarColetas" style={{ margin: 80, marginTop: 10, marginBottom: 10 }}>
              <TextField
                className="input-GerenciarColetas"
                label="Nome"
                variant="outlined"
                size="small"
                // value={numInscricao}
                // onChange={numInscricaoChange}
                sx={{ margin: '5px', backgroundColor: "white" }}
                // onKeyPress={(event) => handleKeyPress(event)}
                autoComplete="off"
              />
              <TextField
                className="input-GerenciarColetas"
                label="CPF"
                variant="outlined"
                size="small"
                // value={numInscricaoReduzida}
                // onChange={(e) => setNumInscricaoReduzida(e.target.value)}
                sx={{ margin: '5px', backgroundColor: "white" }}
                // onKeyPress={(event) => handleKeyPress(event)}
                autoComplete="off"
              />
            </div>
            <Card variant="outlined" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 120, marginTop: 20, marginBottom: 0 }}>

              <TableContainer component={Paper} sx={{ height: 500 }}>
                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" style={{ fontWeight: 'bold', borderBottom: '2.5px solid #1976D2' }}>Tipo</TableCell>
                      <TableCell align="left" style={{ fontWeight: 'bold', borderBottom: '2.5px solid #1976D2' }}>Nome</TableCell>
                      <TableCell align="left" style={{ fontWeight: 'bold', borderBottom: '2.5px solid #1976D2' }}>CPF</TableCell>
                      <TableCell align="left" style={{ fontWeight: 'bold', borderBottom: '2.5px solid #1976D2' }}>email</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="left">Coletor</TableCell>
                      <TableCell align="left">Dac Engenharia</TableCell>
                      <TableCell align="left">000.111.222-33</TableCell>
                      <TableCell align="left">admin.dac@gmail.com</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>


            </Card>

          </div>
          <div className="barra-direita-GerenciarColetas">
            <Fab sx={{ margin: '5px', marginBottom: '-10px', fontSize: '20px' }} color="primary" onClick={submit}>
              {searching ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              )}
            </Fab>
          </div>
        </div>

      }

    </div >
  );
};

export default GerenciarColetas;
