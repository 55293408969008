import React, { useEffect, useRef, useState } from "react";


import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Fab, TextField } from "@mui/material";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faFloppyDisk, faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Api } from "../../../services/Api"
import ModalPontoInteresse from "./ModalPontoInteresse/ModalPontoInteresse";
import ModalEdificacao from "./ModalEdificacao/ModalEdificacao";



import proj4 from "proj4";
import GeoJSON from 'ol/format/GeoJSON.js';
import Map from 'ol/Map.js';
import View from 'ol/View.js';
import { Fill, Stroke, Style } from 'ol/style.js';
import { OSM, Vector as VectorSource } from 'ol/source.js';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer.js';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>

    );
}



TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export default function InfoImoveis({ respostaApi, onBack }) {
    const [value, setValue] = React.useState(0);
    const [searching, setSearching] = useState(false);
    const [modalAberto, setModalAberto] = useState(false);
    const [modalEdificacaoAberto, setModalEdificacaoAberto] = useState(false);
    const [showPontoInteresse, setShowPontoInteresse] = useState([]);
    const [resultReturned, setResultReturned] = useState(false);
    const [pontoInteresse, setPontoInteresse] = useState({});

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [inputs, setInputs] = useState({
        //lote
        //obrigatorio
        loteId: respostaApi.lote.id,
        loteAreaTerreno: respostaApi.lote.areaTerreno,
        loteAreaTerrenoIsento: respostaApi.lote.areaTerrenoIsento,
        loteAreaConstrucao: respostaApi.lote.areaConstrucao,
        loteFracaoIdeal: respostaApi.lote.fracaoIdealInfo,
        //opcional
        loteTopografia: respostaApi.lote.topografia,
        lotePedologia: respostaApi.lote.pedologia,
        loteNivelacao: respostaApi.lote.nivelacao,
        loteTipo: respostaApi.lote.tipo,
        loteSegmento: respostaApi.lote.segmento,
        loteLado: respostaApi.lote.lado,
        loteOcupacao: respostaApi.lote.ocupacao,
        loteUtilizacao: respostaApi.lote.utilizacao,
        lotePatrimonio: respostaApi.lote.patrimonio,
        loteFinalidade: respostaApi.lote.finalidade,
        loteMuro: respostaApi.lote.muro,
        lotePasseio: null,
        loteFrentes: respostaApi.lote.frentes,
        loteArvore: respostaApi.lote.arvore,
        loteFormato: respostaApi.lote.formato,
        lotePredial: respostaApi.lote.predial,
        loteNivel: respostaApi.lote.nivel,
        loteSituacaoLote: respostaApi.lote.situacaoLote,
        loteProfundidade: respostaApi.lote.profundidade,
        loteConservacao: respostaApi.lote.conservacao,
        loteObservacao: null,


        //unidade
        //obrigatorio
        unidadeId: respostaApi.unidade.id,
        unidadeAreaEdicula: respostaApi.unidade.areaEdicula,
        unidadeAreaConstrucao: respostaApi.unidade.areaConstrucao,
        //opcional
        unidadeTipo: respostaApi.unidade.tipo,
        unidadeConservacao: respostaApi.unidade.conservacao,
        unidadePosicao: respostaApi.unidade.posicao,
        unidadeEstrutura: respostaApi.unidade.estrutura,
        unidadeCobertura: respostaApi.unidade.cobertura,
        unidadeInstSanitaria: respostaApi.unidade.instSanitaria,
        unidadeInstEletrica: respostaApi.unidade.instEletrica,
        unidadeCategoria: respostaApi.unidade.categoria,
        unidadePiso: respostaApi.unidade.piso,
        unidadeRevestimento: respostaApi.unidade.revestimento,
        unidadeFachada: respostaApi.unidade.fachada,
        unidadeSituacao: respostaApi.unidade.situacao,
        unidadeForro: respostaApi.unidade.forro,
        unidadeParede: respostaApi.unidade.parede,
        unidadeEsquadrias: respostaApi.unidade.esquadrias,
        unidadeAcesso: respostaApi.unidade.acesso,
        unidadeClassificacao: respostaApi.unidade.classificacao,
        unidadeAlinhamento: respostaApi.unidade.alinhamento,
        unidadeUtilizacao: respostaApi.unidade.utilizacao,
        unidadeObservacao: null,


        //obrigatorio
        enderecoId: respostaApi.endereco.id,
        logradouroId: respostaApi.endereco.logradouro.id,
        testada1: respostaApi.testada.face,
        bairro: respostaApi.endereco.bairro,
        numero: respostaApi.endereco.numero,
        complemento: respostaApi.endereco.complemento,

    });

    const InputChange = (event) => {
        const name = event.target.name;
        const valor = event.target.value;
        setInputs(valores => ({ ...valores, [name]: valor }))
    }
    const submitForm = async (event) => {
        event.preventDefault();
        const novoInputs = Object.fromEntries(Object.entries(inputs).filter(([id, valor]) => valor !== null));

        setSearching(true);
        const response = await Api.imoveisSavePost(novoInputs);
        setSearching(false);
    }

    const [coordenadasPoligono, setCoordenadasPoligono] = useState(
        respostaApi.lote.geom.coordinates[0][0]
    );
    useEffect(() => async () => {
        mostrarPontoInteresse();
        configurarCoordenadaCentral();
        configurarCoordenada(coordenadasPoligono);
    }, []);

    async function apagarPontoInteresse(id) {
        try {
            const response = await Api.deletePontoInteresse(id);
            const totalPontoInteresse = parseInt(localStorage.getItem("infoTotalPontoInteresse")) - 1;
            localStorage.setItem("infoTotalPontoInteresse", totalPontoInteresse);
            mostrarPontoInteresse();
        } catch (error) {

        }
    };

    async function mostrarPontoInteresse() {
        try {
            const response = await Api.getPontoInteresse(localStorage.getItem("infoTotalPontoInteresse"));
            setShowPontoInteresse(response.items);
            setResultReturned(true);
        } catch (error) {
            setResultReturned(false);
        }
    };

    const abrirModal = (ponto) => {
        setPontoInteresse(ponto);
        setModalAberto(true);
    };

    const atualizar = () => {
        mostrarPontoInteresse();
    }

    const styles = {
        'MultiPolygon': new Style({
            stroke: new Stroke({
                color: 'rgb(255, 188, 73)',
                width: 2,
            }),
            fill: new Fill({
                color: 'rgb(255, 188, 73, 0.4)',
            }),
        }),
    };
    const styleFunction = function (feature) {
        return styles[feature.getGeometry().getType()];
    };

    const configurarCoordenada = (coordenadasPoligono) => {
        const utmProjection = '+proj=utm +zone=23 +south +ellps=WGS84 +datum=WGS84 +units=m +no_defs';
        const latLngProjection = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';

        const latLngCoordinatesArray = coordenadasPoligono.map((coordenadas) => {
            return proj4(utmProjection, latLngProjection, coordenadas);
        });
        setCoordenadasPoligono(latLngCoordinatesArray);
    };

    const geojsonObject = {
        'type': 'FeatureCollection',
        'crs': {
            'type': 'name',
            'properties': {
                'name': 'EPSG:4326',
            },
        },
        'features': [
            {
                'type': 'Feature',
                'geometry': {
                    'type': 'MultiPolygon',
                    'coordinates': [
                        [
                            coordenadasPoligono
                        ],
                    ],
                },
            },
        ],
    };

    const vectorSource = new VectorSource({
        features: new GeoJSON().readFeatures(geojsonObject),
    });


    const vectorLayer = new VectorLayer({
        source: vectorSource,
        style: styleFunction,
    });

    const mapRef = useRef(null);
    const [coordenadas, setCoordenadas] = useState([]);

    const configurarCoordenadaCentral = () => {
        const utmProjection = '+proj=utm +zone=23 +south +ellps=WGS84 +datum=WGS84 +units=m +no_defs';
        const latLngProjection = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';

        const coordenadasLimpo = respostaApi.areaCobertaCentro[0].geom.coordinates;
        const latLngCoordinates = proj4(utmProjection, latLngProjection, coordenadasLimpo);
        setCoordenadas(latLngCoordinates);
    }

    useEffect(() => {
        const newMap = new Map({
            target: mapRef.current,
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
                vectorLayer,
            ],
            view: new View({
                center: [coordenadas[0], coordenadas[1]],
                zoom: 19,
                projection: 'EPSG:4326',
            }),
        });
        newMap.on("pointermove", function (event) {
            var hit = this.forEachFeatureAtPixel(event.pixel, (f) => true);
            hit ? this.getTargetElement().style.cursor = 'pointer' : this.getTargetElement().style.cursor = '';
        });
        newMap.on('singleclick', (event) => {
            const clickedFeature = newMap.forEachFeatureAtPixel(event.pixel, (f) => f);
            if (clickedFeature) {
                setModalEdificacaoAberto(true);
            }
        });
        return () => {
            newMap.dispose();
        };
    }, [value]);

    return (
        <>

            <div className="container-imovel">

                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Geral" {...a11yProps(0)} />
                            <Tab label="Edificação" {...a11yProps(1)} />
                            <Tab label="Terreno" {...a11yProps(2)} />
                            <Tab label="Proprietário" {...a11yProps(3)} />
                            <Tab label="Arquivos" {...a11yProps(4)} />
                        </Tabs>
                    </Box>
                    <form>
                        {/* GERAL */}
                        <TabPanel value={value} index={0}>
                            <div className="conteudo-lista">
                                <div className="conteudo-imovel">
                                    <TextField
                                        className="input-imovel desabilitado"
                                        label="N° de inscrição"
                                        variant="outlined"
                                        size="small"
                                        disabled
                                        value={respostaApi.inscricaoImovel}
                                        sx={{ margin: '5px' }}
                                    />
                                    <TextField
                                        className="input-imovel desabilitado"
                                        label="N° de inscrição reduzido"
                                        variant="outlined"
                                        size="small"
                                        disabled
                                        value={inputs.enderecoId}
                                        sx={{ margin: '5px' }}
                                    />
                                </div>
                                <div className="conteudo-imovel">
                                    <TextField
                                        className="input-imovel desabilitado"
                                        label="Logradouro"
                                        variant="outlined"
                                        size="small"
                                        disabled
                                        value={respostaApi.endereco.logradouro.nome}
                                        sx={{ margin: '5px' }}
                                    />
                                </div>
                                <div className="conteudo-imovel">
                                    <TextField
                                        className="input-imovel"
                                        label="Número"
                                        variant="outlined"
                                        size="small"
                                        value={inputs.numero}
                                        sx={{ margin: '5px' }}
                                        name="numero"
                                        onChange={InputChange}
                                        autoComplete="off"
                                    />
                                    <TextField
                                        className="input-imovel"
                                        label="Complemento"
                                        variant="outlined"
                                        size="small"
                                        sx={{ margin: '5px' }}
                                        value={inputs.complemento}
                                        name="complemento"
                                        onChange={InputChange}
                                        autoComplete="off"

                                    />
                                    <TextField
                                        className="input-imovel"
                                        label="Bairro"
                                        variant="outlined"
                                        size="small"
                                        sx={{ margin: '5px' }}
                                        value={inputs.bairro}
                                        name="bairro"
                                        onChange={InputChange}
                                        autoComplete="off"
                                    />
                                </div>
                                <div className="conteudo-imovel">
                                    <TextField
                                        className="input-imovel"
                                        label="Área edicula"
                                        variant="outlined"
                                        size="small"
                                        sx={{ margin: '5px' }}
                                        value={inputs.unidadeAreaEdicula}
                                        name="areaEdicula"
                                        onChange={InputChange}
                                        autoComplete="off"
                                    />
                                    <TextField
                                        className="input-imovel"
                                        label="Unidade área construção"
                                        variant="outlined"
                                        size="small"
                                        sx={{ margin: '5px' }}
                                        value={inputs.unidadeAreaConstrucao}
                                        name="unidadeAreaConstrucao"
                                        onChange={InputChange}
                                        autoComplete="off"

                                    />
                                </div>
                                <div className="conteudo-imovel">
                                    <TextField
                                        className="input-imovel"
                                        label="Área terreno"
                                        variant="outlined"
                                        size="small"
                                        sx={{ margin: '5px' }}
                                        value={inputs.loteAreaTerreno}
                                        name="areaTerreno"
                                        onChange={InputChange}
                                        autoComplete="off"
                                    />
                                    <TextField
                                        className="input-imovel"
                                        label="Área terreno isento"
                                        variant="outlined"
                                        size="small"
                                        sx={{ margin: '5px' }}
                                        value={inputs.loteAreaTerrenoIsento}
                                        name="areaTerrenoIsento"
                                        onChange={InputChange}
                                        autoComplete="off"
                                    />
                                    <TextField
                                        className="input-imovel"
                                        label="Área construção"
                                        variant="outlined"
                                        size="small"
                                        sx={{ margin: '5px' }}
                                        value={inputs.loteAreaConstrucao}
                                        name="areaConstrucao"
                                        onChange={InputChange}
                                        autoComplete="off"
                                    />
                                </div>
                                <div className="conteudo-imovel">
                                    <TextField
                                        className="input-imovel"
                                        label="Fração ideal do lote"
                                        variant="outlined"
                                        size="small"
                                        sx={{ margin: '5px' }}
                                        value={inputs.loteFracaoIdeal}
                                        name="fracaoIdealInfo"
                                        onChange={InputChange}
                                        autoComplete="off"
                                    />
                                    <TextField
                                        className="input-imovel"
                                        label="Testada principal"
                                        variant="outlined"
                                        size="small"
                                        sx={{ margin: '5px' }}
                                        value={inputs.testada1}
                                        name="testada1"
                                        onChange={InputChange}
                                        autoComplete="off"
                                    />
                                </div>
                                <div className="conteudo-imovel">
                                    <div className="linha">
                                    </div>
                                </div>

                                <div className="conteudo-imovel" style={{ marginBottom: '0px', margin: '5px' }}>
                                    <p className="input-imovel">Ponto de Interesse</p>
                                    <p className="input-imovel">Nome</p>
                                    <Button style={{ width: '50%' }} variant="outlined" startIcon={<AddIcon />} onClick={() => abrirModal({})}>
                                        Ponto de Interesse
                                    </Button>
                                </div>
                                <div className="conteudo-imovel">
                                    <div className="linha">
                                    </div>
                                </div>

                                {resultReturned && (
                                    <div style={{ marginBottom: '0px', margin: '5px' }}>
                                        {showPontoInteresse.map((ponto, index) => (
                                            <div key={index} className="conteudo-imovel">
                                                <a className="input-imovel" style={{ color: '#1976D2', cursor: 'pointer' }} onClick={() => abrirModal(ponto)}>
                                                    {ponto.tipo}
                                                </a>
                                                <a className="input-imovel" style={{ color: '#1976D2', cursor: 'pointer' }} onClick={() => abrirModal(ponto)}>
                                                    {ponto.nome}
                                                </a>
                                                <Button style={{ marginLeft: '15%', color: 'red', borderColor: 'red' }} variant="outlined" onClick={() => apagarPontoInteresse(ponto.id)}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </Button>
                                            </div>
                                        ))}
                                    </div>
                                )}

                            </div>
                        </TabPanel>
                        {/* EDIFICACAO */}
                        <TabPanel value={value} index={1}>
                            <div className="conteudo-lista">
                                <div className="conteudo-imovel">
                                    <FormControl sx={{ width: '100%', margin: '5px' }} size="small">
                                        <InputLabel >Tipo</InputLabel>
                                        <Select
                                            label="Tipo"
                                            value={inputs.unidadeTipo}
                                            name="unidadeTipo"
                                            onChange={InputChange}
                                            autoComplete="off"
                                        >
                                            <MenuItem value={null} >Tipo...</MenuItem>
                                            <MenuItem value="apartamento" >Apartamento</MenuItem>
                                            <MenuItem value="casa/sobrado" >Casa/Sobrado</MenuItem>
                                            <MenuItem value="Comercial" >Comercial</MenuItem>
                                            <MenuItem value="fabrica/industria" >Fábrica/Indústria</MenuItem>
                                            <MenuItem value="galpao" >Galpão</MenuItem>
                                            <MenuItem value="residencial/comercial" >Residencial/Comercial</MenuItem>
                                            <MenuItem value="sala/loja" >Sala/Loja</MenuItem>
                                            <MenuItem value="telheiros" >Telheiros</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl sx={{ width: '100%', margin: '5px' }} size="small">
                                        <InputLabel >Posição</InputLabel>
                                        <Select
                                            label="Posição"
                                            value={inputs.unidadePosicao}
                                            name="unidadePosicao"
                                            onChange={InputChange}
                                            autoComplete="off"
                                        >
                                            <MenuItem value={null} >Posição...</MenuItem>
                                            <MenuItem value="FRENTE" >Frente</MenuItem>
                                            <MenuItem value="FUNDOS" >Fundos</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl sx={{ width: '100%', margin: '5px' }} size="small">
                                        <InputLabel >Estrutura</InputLabel>
                                        <Select
                                            label="Estrutura"
                                            value={inputs.unidadeEstrutura}
                                            name="unidadeEstrutura"
                                            onChange={InputChange}
                                            autoComplete="off"
                                        >
                                            <MenuItem value={null}>Estrutura...</MenuItem>
                                            <MenuItem value="ALVENARIA" >Alvenaria</MenuItem>
                                            <MenuItem value="CONCRETO" >Concreto</MenuItem>
                                            <MenuItem value="MADEIRA" >Madeira</MenuItem>
                                            <MenuItem value="METALICA" >Metalica</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl sx={{ width: '100%', margin: '5px' }} size="small">
                                        <InputLabel >Cobertura</InputLabel>
                                        <Select
                                            label="Cobertura"
                                            value={inputs.unidadeCobertura}
                                            name="unidadeCobertura"
                                            onChange={InputChange}
                                            autoComplete="off"
                                        >
                                            <MenuItem value={null} >Cobertura...</MenuItem>
                                            <MenuItem value="ESPECIAL" >Especial</MenuItem>
                                            <MenuItem value="LAJE" >Laje</MenuItem>
                                            <MenuItem value="TELHA BARRO" >Telha Barro</MenuItem>
                                            <MenuItem value="TELHA FIBRA" >Telha Fibrocimento</MenuItem>
                                            <MenuItem value="ZINCO PALHA" >Zinco Palha</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="conteudo-imovel">
                                    <FormControl sx={{ width: '100%', margin: '5px' }} size="small">
                                        <InputLabel >Conservação</InputLabel>
                                        <Select
                                            label="Conservação"
                                            value={inputs.unidadeConservacao}
                                            name="unidadeConservacao"
                                            onChange={InputChange}
                                            autoComplete="off"
                                        >
                                            <MenuItem value={null} >Conservação...</MenuItem>
                                            <MenuItem value="Boa" >Boa</MenuItem>
                                            <MenuItem value="Ma" >Má</MenuItem>
                                            <MenuItem value="Otimo/novo" >Ótimo/Novo</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl sx={{ width: '100%', margin: '5px' }} size="small">
                                        <InputLabel >Instalação Sanitária</InputLabel>
                                        <Select
                                            label="InstalacaoSanitaria"
                                            value={inputs.unidadeInstSanitaria}
                                            name="unidadeInstSanitaria"
                                            onChange={InputChange}
                                            autoComplete="off"
                                        >
                                            <MenuItem value={null} >Instalação Sanitária...</MenuItem>
                                            <MenuItem value="+1 INTERNA" >Mais de uma interna</MenuItem>
                                            <MenuItem value="EXTERNA" >Externa</MenuItem>
                                            <MenuItem value="INEXISTENTE" >Inexistente</MenuItem>
                                            <MenuItem value="INT.COMPL." >Int. Completa</MenuItem>
                                            <MenuItem value="INT.SIMPL." >Int. Simples</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl sx={{ width: '100%', margin: '5px' }} size="small">
                                        <InputLabel >Instalação Elétrica</InputLabel>
                                        <Select
                                            label="InstalacaoEletrica"
                                            value={inputs.unidadeInstEletrica}
                                            name="unidadeInstEletrica"
                                            onChange={InputChange}
                                            autoComplete="off"
                                        >
                                            <MenuItem value={null} >Instalação Elétrica...</MenuItem>
                                            <MenuItem value="APARENTE" >Aparente</MenuItem>
                                            <MenuItem value="EMBUTIDA" >Embutida</MenuItem>
                                            <MenuItem value="INEXISTE" >Inexistente</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl sx={{ width: '100%', margin: '5px' }} size="small">
                                        <InputLabel >Categoria</InputLabel>
                                        <Select
                                            label="Categoria"
                                            value={inputs.unidadeCategoria}
                                            name="unidadeCategoria"
                                            onChange={InputChange}
                                            autoComplete="off"
                                        >
                                            <MenuItem value={null} >Categoria...</MenuItem>
                                            <MenuItem value="APARTAMENTO" >Apartamento</MenuItem>
                                            <MenuItem value="CASA" >Casa</MenuItem>
                                            <MenuItem value="GALPAO" >Galpão</MenuItem>
                                            <MenuItem value="INDUSTRIA" >Indústria</MenuItem>
                                            <MenuItem value="LOJA" >Loja</MenuItem>
                                            <MenuItem value="TELHEIRO" >Telheiro</MenuItem>
                                            <MenuItem value="ESPECIAL" >Especial</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="conteudo-imovel">
                                    <FormControl sx={{ width: '100%', margin: '5px' }} size="small">
                                        <InputLabel >Piso</InputLabel>
                                        <Select
                                            label="Piso"
                                            value={inputs.unidadePiso}
                                            name="unidadePiso"
                                            onChange={InputChange}
                                            autoComplete="off"
                                        >
                                            <MenuItem value={null} >Piso...</MenuItem>
                                            <MenuItem value="CERAMICA" >Cerâmica</MenuItem>
                                            <MenuItem value="CIMENTO" >Cimento</MenuItem>
                                            <MenuItem value="ESPECIAL" >Especial</MenuItem>
                                            <MenuItem value="MAT.PLAST" >Mat. Plástico</MenuItem>
                                            <MenuItem value="TABUAS" >Tabuas</MenuItem>
                                            <MenuItem value="TACO" >Taco</MenuItem>
                                            <MenuItem value="TERRA" >Terra</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl sx={{ width: '100%', margin: '5px' }} size="small">
                                        <InputLabel >Revestimento</InputLabel>
                                        <Select
                                            label="Revestimento"
                                            value={inputs.unidadeRevestimento}
                                            name="unidadeRevestimento"
                                            onChange={InputChange}
                                            autoComplete="off"
                                        >
                                            <MenuItem value={null}>Revestimento...</MenuItem>
                                            <MenuItem value="ALVENARIA">Alvenaria</MenuItem>
                                            <MenuItem value="CAIACAO">Caição</MenuItem>
                                            <MenuItem value="CERAMICA">Cerâmica</MenuItem>
                                            <MenuItem value="ESPECIAL">Especial</MenuItem>
                                            <MenuItem value="MADEIRA">Madeira</MenuItem>
                                            <MenuItem value="OLEO">Óleo</MenuItem>
                                            <MenuItem value="REBOCO">Reboco</MenuItem>
                                            <MenuItem value="SEM">Sem</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl sx={{ width: '100%', margin: '5px' }} size="small">
                                        <InputLabel >Fachada</InputLabel>
                                        <Select
                                            label="Fachada"
                                            value={inputs.unidadeFachada}
                                            name="unidadeFachada"
                                            onChange={InputChange}
                                            autoComplete="off"
                                        >
                                            <MenuItem value={null} >Fachada...</MenuItem>
                                            <MenuItem value="ALINHADA">Alinhada</MenuItem>
                                            <MenuItem value="RECUADA">Recuada</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl sx={{ width: '100%', margin: '5px' }} size="small">
                                        <InputLabel >Situação</InputLabel>
                                        <Select
                                            label="Situacao"
                                            value={inputs.unidadeSituacao}
                                            name="unidadeSituacao"
                                            onChange={InputChange}
                                            autoComplete="off"
                                        >
                                            <MenuItem value={null}>Situação...</MenuItem>
                                            <MenuItem value="CONJUGADA">Conjugada</MenuItem>
                                            <MenuItem value="GEMINADA">Geminada</MenuItem>
                                            <MenuItem value="ISOLADA">Isolada</MenuItem>
                                        </Select>

                                    </FormControl>
                                </div>

                                <div className="conteudo-imovel">
                                    <FormControl sx={{ width: '100%', margin: '5px' }} size="small">
                                        <InputLabel >Forro</InputLabel>
                                        <Select
                                            label="Forro"
                                            value={inputs.unidadeForro}
                                            name="unidadeForro"
                                            onChange={InputChange}
                                            autoComplete="off"
                                        >
                                            <MenuItem value={null}>Forro...</MenuItem>
                                            <MenuItem value="LAJE">Laje</MenuItem>
                                            <MenuItem value="CHAPAS">Chapas</MenuItem>
                                            <MenuItem value="ESPECIAL">Especial</MenuItem>
                                            <MenuItem value="ESTUQUE">Estuque</MenuItem>
                                            <MenuItem value="INEXISTE">Inexistente</MenuItem>
                                            <MenuItem value="MADEIRA">Madeira</MenuItem>
                                            <MenuItem value="SEM">Sem</MenuItem>
                                        </Select>

                                    </FormControl>
                                    <FormControl sx={{ width: '100%', margin: '5px' }} size="small">
                                        <InputLabel >Parede</InputLabel>
                                        <Select
                                            label="Parede"
                                            value={inputs.unidadeParede}
                                            name="unidadeParede"
                                            onChange={InputChange}
                                            autoComplete="off"
                                        >
                                            <MenuItem value={null}>Parede...</MenuItem>
                                            <MenuItem value="ALVENARIA">Alvenaria</MenuItem>
                                            <MenuItem value="CHOCA_BARRACO">Choça/Barraco</MenuItem>
                                            <MenuItem value="MADEIRA">Madeira</MenuItem>
                                            <MenuItem value="TAIPA">Taipa</MenuItem>
                                        </Select>

                                    </FormControl>
                                    <FormControl sx={{ width: '100%', margin: '5px' }} size="small">
                                        <InputLabel >Esquadras</InputLabel>
                                        <Select
                                            label="Esquadrias"
                                            value={inputs.unidadeEsquadrias}
                                            name="unidadeEsquadrias"
                                            onChange={InputChange}
                                            autoComplete="off"
                                        >
                                            <MenuItem value={null}>Esquadras...</MenuItem>
                                            <MenuItem value="MADEIRA">Madeira</MenuItem>
                                            <MenuItem value="FERRO">Ferro</MenuItem>
                                            <MenuItem value="ALUMINIO">Alumínio</MenuItem>
                                            <MenuItem value="ESPECIAL">Especial</MenuItem>
                                        </Select>

                                    </FormControl>

                                    <FormControl sx={{ width: '100%', margin: '5px' }} size="small">
                                        <InputLabel >Acesso</InputLabel>
                                        <Select
                                            label="Acesso"
                                            value={inputs.unidadeAcesso}
                                            name="unidadeAcesso"
                                            onChange={InputChange}
                                            autoComplete="off"
                                        >
                                            <MenuItem value={null}>Acesso...</MenuItem>
                                            <MenuItem value="PERMITIDO">Permitido</MenuItem>
                                            <MenuItem value="NAO_PERMITIDO">Não Permitido</MenuItem>
                                            <MenuItem value="IMOVEL_VAZIO">Imóvel Vazio</MenuItem>
                                        </Select>

                                    </FormControl>
                                </div>
                                <div className="conteudo-imovel">

                                    <FormControl sx={{ width: '100%', margin: '5px' }} size="small">
                                        <InputLabel >Classificação</InputLabel>
                                        <Select
                                            label="Classificação"
                                            value={inputs.unidadeClassificacao}
                                            name="unidadeClassificacao"
                                            onChange={InputChange}
                                            autoComplete="off"
                                        >
                                            <MenuItem value={null}>Classificação...</MenuItem>
                                            <MenuItem value="RUSTICA">Rústica</MenuItem>
                                            <MenuItem value="POPULAR">Popular</MenuItem>
                                            <MenuItem value="MEDIA">Média</MenuItem>
                                            <MenuItem value="BOA">Boa</MenuItem>
                                            <MenuItem value="LUXO">Luxo</MenuItem>
                                        </Select>

                                    </FormControl>
                                    <FormControl sx={{ width: '100%', margin: '5px' }} size="small">
                                        <InputLabel >Alinhamento</InputLabel>
                                        <Select
                                            label="Alinhamento"
                                            value={inputs.unidadeAlinhamento}
                                            name="unidadeAlinhamento"
                                            onChange={InputChange}
                                            autoComplete="off"
                                        >
                                            <MenuItem value={null}>Alinhamento...</MenuItem>
                                            <MenuItem value="FRENTE_ALINHADA">Frente Alinhada</MenuItem>
                                            <MenuItem value="FRENTE_RECUADA">Frente Recuada</MenuItem>
                                            <MenuItem value="FUNDOS">Fundos</MenuItem>
                                        </Select>

                                    </FormControl>
                                    <FormControl sx={{ width: '100%', margin: '5px' }} size="small">
                                        <InputLabel >Utilização</InputLabel>
                                        <Select
                                            label="Utilização"
                                            value={inputs.unidadeUtilizacao}
                                            name="unidadeUtilizacao"
                                            onChange={InputChange}
                                            autoComplete="off"
                                        >
                                            <MenuItem value={null}>Utilização...</MenuItem>
                                            <MenuItem value="COMERCIAL">Comercial</MenuItem>
                                            <MenuItem value="ESCOLAR">Escolar</MenuItem>
                                            <MenuItem value="HOSPITALAR">Hospitalar</MenuItem>
                                            <MenuItem value="HOTELARIA">Hotelaria</MenuItem>
                                            <MenuItem value="IND_AGROPECUARIA">Ind. Agropecuária</MenuItem>
                                            <MenuItem value="PRESTACAO_DE_SERVICOS">Prestação de Serviços</MenuItem>
                                            <MenuItem value="RESIDENCIAL">Residencial</MenuItem>
                                            <MenuItem value="SERV_PUBLICO">Serv. Público</MenuItem>
                                            <MenuItem value="TERRENO_VAGO">Terreno Vago</MenuItem>
                                            <MenuItem value="OUTROS">Outros</MenuItem>
                                        </Select>

                                    </FormControl>
                                    <TextField
                                        className="input-imovel"
                                        label="Observação"
                                        variant="outlined"
                                        size="small"
                                        sx={{ margin: '5px' }}
                                        value={inputs.unidadeObservacao}
                                        name="unidadeObservacao"
                                        onChange={InputChange}
                                        autoComplete="off"
                                    />

                                </div>

                                <div className="conteudo-imovel">
                                    <div className="linha">
                                    </div>
                                </div>
                                <div className="conteudo-imovel" style={{ justifyContent: 'start' }}>
                                    <p>Selecione uma área coberta do imóvel, para visualizar e alterar suas informações sobre o número de pavimentos e beiral</p>
                                </div>

                                <div className="conteudo-imovel">
                                    <div ref={mapRef} style={{ width: '100%', height: '35vh' }} />
                                </div>
                            </div>
                        </TabPanel>
                        {/* TERRENO */}
                        <TabPanel value={value} index={2}>
                            <div className="conteudo-lista">
                                <div className="conteudo-imovel">
                                    <FormControl sx={{ width: '100%', margin: '5px' }} size="small">
                                        <InputLabel >Topografia</InputLabel>
                                        <Select
                                            label="Topografia"
                                            value={inputs.loteTopografia}
                                            name="loteTopografia"
                                            onChange={InputChange}
                                            autoComplete="off"
                                        >
                                            <MenuItem value={null} >Topografia...</MenuItem>
                                            <MenuItem value="Aclive/Declive" >Aclive/Declive</MenuItem>
                                            <MenuItem value="Dificil Aproveitamento" >Difícil Aproveitamento</MenuItem>
                                            <MenuItem value="Regular" >Regular</MenuItem>
                                            <MenuItem value="Irregular" >Irregular</MenuItem>
                                            <MenuItem value="Plano" >Plano</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl sx={{ width: '100%', margin: '5px' }} size="small">
                                        <InputLabel >Pedologia</InputLabel>
                                        <Select
                                            label="Pedologia"
                                            value={inputs.lotePedologia}
                                            name="lotePedologia"
                                            onChange={InputChange}
                                            autoComplete="off"
                                        >
                                            <MenuItem value={null} >Pedologia...</MenuItem>
                                            <MenuItem value="FIRME" >Firme</MenuItem>
                                            <MenuItem value="INUNDAVEL" >Inundável</MenuItem>
                                            <MenuItem value="MISTO" >Misto</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl sx={{ width: '100%', margin: '5px' }} size="small">
                                        <InputLabel >Ocupação</InputLabel>
                                        <Select
                                            label="Ocupação"
                                            value={inputs.loteOcupacao}
                                            name="loteOcupacao"
                                            onChange={InputChange}
                                            autoComplete="off"
                                        >
                                            <MenuItem value={null}>Ocupação...</MenuItem>
                                            <MenuItem value="Construção Paralizada">Construção Paralizada</MenuItem>
                                            <MenuItem value="Construido">Construído</MenuItem>
                                            <MenuItem value="Em Construção">Em Construção</MenuItem>
                                            <MenuItem value="Não Edificado">Não Edificado</MenuItem>
                                            <MenuItem value="Reforma/Demolição">Reforma/Demolição</MenuItem>
                                            <MenuItem value="Ruínas">Ruínas</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl sx={{ width: '100%', margin: '5px' }} size="small">
                                        <InputLabel >Utilização</InputLabel>
                                        <Select
                                            label="Utilização"
                                            value={inputs.loteUtilizacao}
                                            name="loteUtilizacao"
                                            onChange={InputChange}
                                            autoComplete="off"
                                        >
                                            <MenuItem value={null}>Utilização...</MenuItem>
                                            <MenuItem value="Comercial">Comercial</MenuItem>
                                            <MenuItem value="Escolar">Escolar</MenuItem>
                                            <MenuItem value="Hospitalar">Hospitalar</MenuItem>
                                            <MenuItem value="Hotelaria">Hotelaria</MenuItem>
                                            <MenuItem value="Ind. Agropecuária">Ind. Agropecuária</MenuItem>
                                            <MenuItem value="Prestação de Serviços">Prestação de Serviços</MenuItem>
                                            <MenuItem value="Residencial">Residencial</MenuItem>
                                            <MenuItem value="Serv. Público">Serv. Público</MenuItem>
                                            <MenuItem value="Terreno Vago">Terreno Vago</MenuItem>
                                            <MenuItem value="Outros">Outros</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="conteudo-imovel">
                                    <FormControl sx={{ width: '100%', margin: '5px' }} size="small">
                                        <InputLabel >Patrimônio</InputLabel>
                                        <Select
                                            label="Patrimônio"
                                            value={inputs.lotePatrimonio}
                                            name="lotePatrimonio"
                                            onChange={InputChange}
                                            autoComplete="off"
                                        >
                                            <MenuItem value={null}>Patrimônio...</MenuItem>
                                            <MenuItem value="Estadual">Estadual</MenuItem>
                                            <MenuItem value="Municipal">Municipal</MenuItem>
                                            <MenuItem value="Particular">Particular</MenuItem>
                                            <MenuItem value="Público">Público</MenuItem>
                                            <MenuItem value="Religioso">Religioso</MenuItem>
                                        </Select>

                                    </FormControl>

                                    <FormControl sx={{ width: '100%', margin: '5px' }} size="small">
                                        <InputLabel >Finalidade</InputLabel>
                                        <Select
                                            label="Finalidade"
                                            value={inputs.loteFinalidade}
                                            name="loteFinalidade"
                                            onChange={InputChange}
                                            autoComplete="off"
                                        >
                                            <MenuItem value={null}>Finalidade...</MenuItem>
                                            <MenuItem value="Uso próprio">Uso próprio</MenuItem>
                                            <MenuItem value="Locatícia">Locatícia</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl sx={{ width: '100%', margin: '5px' }} size="small">
                                        <InputLabel >Muro</InputLabel>
                                        <Select
                                            label="Muro"
                                            value={inputs.loteMuro}
                                            name="loteMuro"
                                            onChange={InputChange}
                                            autoComplete="off"
                                        >
                                            <MenuItem value={null}>Muro...</MenuItem>
                                            <MenuItem value="Sim">Sim</MenuItem>
                                            <MenuItem value="Não">Não</MenuItem>
                                            <MenuItem value="Sem info.">Sem info.</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl sx={{ width: '100%', margin: '5px' }} size="small">
                                        <InputLabel >Passeio</InputLabel>
                                        <Select
                                            label="Passeio"
                                            value={inputs.lotePasseio}
                                            name="lotePasseio"
                                            onChange={InputChange}
                                            autoComplete="off"
                                        >
                                            <MenuItem value={null}>Passeio...</MenuItem>
                                            <MenuItem value="Sim">Sim</MenuItem>
                                            <MenuItem value="Não">Não</MenuItem>
                                            <MenuItem value="Sem info.">Sem info.</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="conteudo-imovel">
                                    <FormControl sx={{ width: '100%', margin: '5px' }} size="small">
                                        <InputLabel >Frentes</InputLabel>
                                        <Select
                                            label="Frentes"
                                            value={inputs.loteFrentes}
                                            name="loteFrentes"
                                            onChange={InputChange}
                                            autoComplete="off"
                                        >
                                            <MenuItem value={null}>Frentes...</MenuItem>
                                            <MenuItem value="Encravado">Encravado</MenuItem>
                                            <MenuItem value="Gleba">Gleba</MenuItem>
                                            <MenuItem value="Mais de Uma Frente">Mais de Uma Frente</MenuItem>
                                            <MenuItem value="Uma Frente">Uma Frente</MenuItem>
                                            <MenuItem value="Vila">Vila</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl sx={{ width: '100%', margin: '5px' }} size="small">
                                        <InputLabel >Formato</InputLabel>
                                        <Select
                                            label="Formato"
                                            value={inputs.loteFormato}
                                            name="loteFormato"
                                            onChange={InputChange}
                                            autoComplete="off"
                                        >
                                            <MenuItem value={null}>Formato...</MenuItem>
                                            <MenuItem value="IRREGULAR">Irregular</MenuItem>
                                            <MenuItem value="NAO DEFINIDO">Não Definido</MenuItem>
                                            <MenuItem value="REGULAR">Regular</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl sx={{ width: '100%', margin: '5px' }} size="small">
                                        <InputLabel >Árvore</InputLabel>
                                        <Select
                                            label="Árvore"
                                            value={inputs.loteArvore}
                                            name="loteArvore"
                                            onChange={InputChange}
                                            autoComplete="off"
                                        >
                                            <MenuItem value={null}>Árvore...</MenuItem>
                                            <MenuItem value="N">N</MenuItem>
                                            <MenuItem value="R">R</MenuItem>
                                            <MenuItem value="S">S</MenuItem>
                                            <MenuItem value="V">V</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        className="input-imovel"
                                        label="Nivelação"
                                        variant="outlined"
                                        size="small"
                                        sx={{ margin: '5px' }}
                                        value={inputs.loteNivelacao}
                                        name="loteNivelacao"
                                        onChange={InputChange}
                                        autoComplete="off"
                                    />
                                </div>

                                <div className="conteudo-imovel">
                                    <TextField
                                        className="input-imovel"
                                        label="Tipo"
                                        variant="outlined"
                                        size="small"
                                        sx={{ margin: '5px' }}
                                        value={inputs.loteTipo}
                                        name="loteTipo"
                                        onChange={InputChange}
                                        autoComplete="off"
                                    />
                                    <FormControl sx={{ width: '100%', margin: '5px' }} size="small">
                                        <InputLabel >Conservação</InputLabel>
                                        <Select
                                            label="Conservação"
                                            value={inputs.loteConservacao}
                                            name="loteConservacao"
                                            onChange={InputChange}
                                            autoComplete="off"
                                        >
                                            <MenuItem value={null}>Conservação...</MenuItem>
                                            <MenuItem value="Boa">Boa</MenuItem>
                                            <MenuItem value="Ma">Má</MenuItem>
                                            <MenuItem value="Otimo/Novo">Ótimo/Novo</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        className="input-imovel"
                                        label="Segmento"
                                        variant="outlined"
                                        size="small"
                                        sx={{ margin: '5px' }}
                                        value={inputs.loteSegmento}
                                        name="loteSegmento"
                                        onChange={InputChange}
                                        autoComplete="off"
                                    />
                                    <FormControl sx={{ width: '100%', margin: '5px' }} size="small">
                                        <InputLabel >Lado</InputLabel>
                                        <Select
                                            label="Lado"
                                            value={inputs.loteLado}
                                            name="loteLado"
                                            onChange={InputChange}
                                            autoComplete="off"
                                        >
                                            <MenuItem value={null} >Lado...</MenuItem>
                                            <MenuItem value="D" >Direito</MenuItem>
                                            <MenuItem value="E" >Esquerdo</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="conteudo-imovel">
                                    <FormControl sx={{ width: '100%', margin: '5px' }} size="small">
                                        <InputLabel >Predial</InputLabel>
                                        <Select
                                            label="Predial"
                                            value={inputs.lotePredial}
                                            name="lotePredial"
                                            onChange={InputChange}
                                            autoComplete="off"
                                        >
                                            <MenuItem value={null}>Predial...</MenuItem>
                                            <MenuItem value="s">Sim</MenuItem>
                                            <MenuItem value="n">Não</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl sx={{ width: '100%', margin: '5px' }} size="small">
                                        <InputLabel >Nível</InputLabel>
                                        <Select
                                            label="Nível"
                                            value={inputs.loteNivel}
                                            name="loteNivel"
                                            onChange={InputChange}
                                            autoComplete="off"
                                        >
                                            <MenuItem value={null}>Nível...</MenuItem>
                                            <MenuItem value="Ao Nível">Ao Nível</MenuItem>
                                            <MenuItem value="Abaixo">Abaixo</MenuItem>
                                            <MenuItem value="Acima">Acima</MenuItem>
                                            <MenuItem value="Irregular">Irregular</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl sx={{ width: '100%', margin: '5px' }} size="small">
                                        <InputLabel >Situação lote</InputLabel>
                                        <Select
                                            label="Situação Lote"
                                            value={inputs.loteSituacaoLote}
                                            name="loteSituacaoLote"
                                            onChange={InputChange}
                                            autoComplete="off"
                                        >
                                            <MenuItem value={null}>Situação lote...</MenuItem>
                                            <MenuItem value="Esquina/+ de 1 frente">Esquina/+ de 1 frente</MenuItem>
                                            <MenuItem value="frente">frente</MenuItem>
                                            <MenuItem value="Meio de Quadra">Meio de Quadra</MenuItem>
                                            <MenuItem value="Encravado">Encravado</MenuItem>
                                            <MenuItem value="Gleba">Gleba</MenuItem>
                                            <MenuItem value="Vila/Condomínio">Vila/Condomínio</MenuItem>
                                            <MenuItem value="Sítio Rec.">Sítio Rec.</MenuItem>
                                            <MenuItem value="Esquina/+ de 1 frente">Esquina/+ de 1 frente</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        className="input-imovel"
                                        label="Profundidade"
                                        variant="outlined"
                                        size="small"
                                        sx={{ margin: '5px' }}
                                        value={inputs.loteProfundidade}
                                        name="loteProfundidade"
                                        onChange={InputChange}
                                        autoComplete="off"
                                    />
                                </div>

                                <div className="conteudo-imovel">
                                    <TextField
                                        className="input-imovel"
                                        label="Observação"
                                        variant="outlined"
                                        size="small"
                                        sx={{ margin: '5px', marginRight: '75.5%' }}
                                        value={inputs.loteObservacao}
                                        name="loteObservacao"
                                        onChange={InputChange}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        </TabPanel>
                        {/* PROPRIETARIO */}
                        <TabPanel value={value} index={3}>
                            <div className="conteudo-lista">
                                <div className="conteudo-imovel" style={{ justifyContent: 'start' }}>
                                    <Button style={{ width: '19%', margin: '5px' }} size="small" disabled variant="contained" startIcon={<EditIcon />}>
                                        Trocar proprietário
                                    </Button>
                                </div>
                                <div className="conteudo-imovel">
                                    <TextField
                                        className="input-imovel desabilitado"
                                        label="Proprietário"
                                        variant="outlined"
                                        size="small"
                                        disabled
                                        sx={{ margin: '5px' }}
                                        value={respostaApi.proprietario.nome}
                                        name="proprietario"
                                    />
                                    <TextField
                                        className="input-imovel desabilitado"
                                        label="CPF/CNPJ"
                                        variant="outlined"
                                        size="small"
                                        disabled
                                        sx={{ margin: '5px' }}
                                        value={respostaApi.proprietario.cpfCnpj}
                                        name="cpfCnpj"
                                    />
                                </div>
                            </div>
                        </TabPanel>
                        {/* ARQUIVO */}
                        <TabPanel value={value} index={4}>
                            <div className="conteudo-lista" style={{ height: '50px', padding: 0 }} >
                            </div>
                        </TabPanel>
                    </form>

                </Box>
            </div>

            <div className="barra-direita">
                <Fab sx={{ margin: '5px', marginBottom: '-10px', fontSize: '20px' }} color="primary" onClick={onBack}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                </Fab>
                <Fab sx={{ margin: '5px', marginBottom: '-10px', fontSize: '20px' }} color="primary" onClick={submitForm}>
                    {searching ? (
                        <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                        <FontAwesomeIcon icon={faFloppyDisk} />
                    )}
                </Fab>
            </div>
            {modalAberto && (
                <section style={{ position: 'relative' }}>
                    <ModalPontoInteresse atualizar={atualizar} onClose={() => setModalAberto(false)} pontoInteresse={pontoInteresse} respostaApi={respostaApi} />
                </section>
            )}
            {modalEdificacaoAberto && (
                <section style={{ position: 'relative' }}>
                    <ModalEdificacao onClose={() => setModalEdificacaoAberto(false)} />
                </section>
            )}

        </>
    );
}


