import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { Api } from "../../../services/Api.js";

import "./ModalCadastro.css";

const ModalCadastro = ({ onClose, AtualizaListaProprietários }) => {
  const [proprietario, setProprietario] = useState("");
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [endereco, setEndereco] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    // API
    requestApiCadastro();

    setProprietario("");
    setCpfCnpj("");
    setSelectedValue("");
    setEndereco("");
  };

  const onCloseClick = () => {
    onClose();
  };

  //Para a chamada da função para sair
  const onModalClick = (e) => {
    e.stopPropagation();
  };

  async function requestApiCadastro() {
    try {
      const infoCadastro = {
        nome: proprietario,
        cpfCnpj: cpfCnpj,
        tipo: selectedValue,
        endereco: endereco,
      };

      const data = await Api.addProprietarios(infoCadastro);

      if (data.statusCode !== undefined) {
        alert("Preencha os campos corretamente");
      } else {
        //atualiza a lista chamando a função da API
        AtualizaListaProprietários();
      }
    } catch (err) {
      // console.log("Deu erro:", err);
    }
  }

  return (
    <div className="ModalCadastro-modal" onClick={onCloseClick}>
      <div className="ModalCadastro-modal-content" onClick={onModalClick}>
        <div className="ModalCadastro-close-bnt">
          <Button
            aria-label="Fechar"
            style={{ padding: 0, minWidth: 0 }}
            onClick={onCloseClick}
          >
            <CloseIcon />
          </Button>
        </div>
        <h2
          style={{
            textAlign: "center",
            marginBottom: "10px",
            paddingTop: "10px",
          }}
        >
          Cadastro
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="ModalCadastro-form-group">
            <label htmlFor="proprietario" className="ModalCadastro-label">
              Proprietário:
            </label>
            <input
              className="ModalCadastro-input"
              type="text"
              id="proprietario"
              value={proprietario}
              onChange={(e) => setProprietario(e.target.value)}
              required
            />
          </div>

          <div className="ModalCadastro-form-row">
            <div className="ModalCadastro-form-group">
              <label htmlFor="selector" className="ModalCadastro-label">
                Selecione:
              </label>
              <select
                className="ModalCadastro-select"
                id="select"
                value={selectedValue}
                onChange={(e) => setSelectedValue(e.target.value)}
                required
              >
                <option value="">Tipo:</option>
                <option value="F">Pessoa Fisica</option>
                <option value="J">Pessoa Juridica</option>
              </select>
            </div>

            <div className="ModalCadastro-form-group">
              <label htmlFor="cpfCnpj" className="ModalCadastro-label">
                CPF/CNPJ:
              </label>
              <input
                className="ModalCadastro-input"
                type="text"
                id="cpfCnpj"
                value={cpfCnpj}
                onChange={(e) => setCpfCnpj(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="ModalCadastro-form-group">
            <label htmlFor="endereco" className="ModalCadastro-label">
              Endereço:
            </label>
            <input
              className="ModalCadastro-input"
              type="text"
              id="ModalCadastro-select"
              value={endereco}
              onChange={(e) => setEndereco(e.target.value)}
              required
            />
          </div>

          <div className="ModalCadastro-form-row">
            <Button
              style={{ width: "100%", marginTop: "10px" }}
              variant="contained"
            >
              <FontAwesomeIcon
                icon={faFloppyDisk}
                style={{ marginRight: "0.5em" }}
              />
              Salvar
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModalCadastro;
