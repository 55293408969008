import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { faEye, faEyeSlash, faFloppyDisk, faCheck, faSpinner  } from "@fortawesome/free-solid-svg-icons";
import { Api } from "../../../services/Api";
import '../ModalNovoUsuario/ModalNovoUsuario.css'

function ModalNovoUsuario({ onClose, atualizar }) {
    const [showPassword, setShowPassword] = useState(false);
    const [novoUsuario, setNovoUsuario] = useState({});
    const [searching, setSearching] = useState(false);
    const [resultReturned, setResultReturned] = useState(false);

    const options = [
        { value: "admin", label: "Administrador" },
        { value: "Assistente", label: "Assistente" },
        { value: "collector", label: "Coletor" },
    ];

    const trocarPassword = () => {
        setShowPassword(!showPassword);
    };

    const InputChange = (event) => {
        const name = event.target.name;
        const valor = event.target.value;
        if (name === 'cpf') {
            const valorFormatado = formataCpf(valor);
            setNovoUsuario(valores => ({ ...valores, [name]: valorFormatado }));
        } else {
            setNovoUsuario(valores => ({ ...valores, [name]: valor }));
        }
    };


    const submitForm = async (event) => {
        event.preventDefault();
        setSearching(true);
        if (novoUsuario.password.length < 6) {
            alert("A senha deve possuir no mínimo 6 caracteres ")
            setSearching(false);
            return;
        }
        if (novoUsuario.password !== novoUsuario.ConfirmarSenha) {
            alert("As senhas não coincidem");
            setSearching(false);
            return;
        }
        const { ConfirmarSenha, ...novoUser } = novoUsuario;
        const response = await Api.newUsers(novoUser);
        if (!response.error) {
            setResultReturned(true);
            const totalUsuarios = parseInt(localStorage.getItem("infoTotalUsuarios")) + 1;
            localStorage.setItem("infoTotalUsuarios", totalUsuarios);
        }
        setSearching(false);
        atualizar();
    };

    const formataCpf = (valor) => {
        valor = valor.replace(/\D/g, '');
        let valorFormatado = '';
        for (let i = 0; i < valor.length; i++) {
            if (i === 3 || i === 6) {
                valorFormatado += '.';
            }
            if (i === 9) {
                valorFormatado += '-';
            }
            valorFormatado += valor[i];
            if (i >= 10) {
                break;
            }
        }
        return valorFormatado;
    };

    return (
        <div className="modalNovoUsuario" onClick={onClose}>
            <div className="modalNovoUsuario-content" onClick={(e) => e.stopPropagation()}>
                <div className="modalNovoUsuario-close-bnt">
                    <Button
                        aria-label="Fechar"
                        style={{ padding: 0, minWidth: 0 }}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </Button>
                </div>

                <h2 className="modalNovoUsuario-titulo">Novo Usuário</h2>
                <form onSubmit={submitForm}>
                    <div className="modalNovoUsuario-form-group">
                        <label htmlFor="name" className="modalNovoUsuario-label">
                            Nome:
                        </label>
                        <input
                            className="modalNovoUsuario-input"
                            type="text"
                            name="name"
                            value={novoUsuario.name}
                            onChange={InputChange}
                            required
                            autoComplete="off"
                        />
                    </div>
                    <div className="modalNovoUsuario-form-row">
                        <div className="modalNovoUsuario-form-group">
                            <label htmlFor="email" className="modalNovoUsuario-label">
                                Email:
                            </label>
                            <input
                                className="modalNovoUsuario-input"
                                type="email"
                                name="email"
                                value={novoUsuario.email}
                                onChange={InputChange}
                                required
                                autoComplete="off"
                            />
                        </div>
                        <div className="modalNovoUsuario-form-group">
                            <label htmlFor="dataNascimento" className="modalNovoUsuario-label">
                                Data de Nascimento:
                            </label>
                            <input
                                className="modalNovoUsuario-input"
                                type="date"
                                name="dataNasc"
                                value={novoUsuario.dataNasc}
                                onChange={InputChange}
                                required
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div className="modalNovoUsuario-form-row">
                        <div className="modalNovoUsuario-form-group">
                            <label htmlFor="cpf" className="modalNovoUsuario-label">
                                CPF:
                            </label>
                            <input
                                className="modalNovoUsuario-input"
                                type="text"
                                name="cpf"
                                value={novoUsuario.cpf}
                                onChange={InputChange}
                                required
                                autoComplete="off"
                            />
                        </div>
                        <div className="modalNovoUsuario-form-group">
                            <label htmlFor="TipoUsuario" className="modalNovoUsuario-label">
                                Tipo de Usuário:
                            </label>
                            <select
                                className="modalNovoUsuario-select"
                                name="role"
                                value={novoUsuario.role}
                                onChange={InputChange}
                                required
                            >
                                {options.map((option) => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="modalNovoUsuario-form-row">
                        <div className="modalNovoUsuario-form-group">
                            <label htmlFor="Senha" className="modalNovoUsuario-label">
                                Senha:
                            </label>
                            <input
                                className="modalNovoUsuario-input"
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                value={novoUsuario.password}
                                onChange={InputChange}
                                required
                                autoComplete="off"

                            />
                        </div>
                        <button type="button" onClick={trocarPassword} className="btn-password">
                            {showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                        </button>
                        <div className="modalNovoUsuario-form-group">
                            <label htmlFor="ConfirmarSenha" className="modalNovoUsuario-label">
                                Confirmar Senha:
                            </label>
                            <input
                                className="modalNovoUsuario-input"
                                type={showPassword ? 'text' : 'password'}
                                name="ConfirmarSenha"
                                value={novoUsuario.ConfirmarSenha}
                                onChange={InputChange}
                                required
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div className="modalNovoUsuario-form-row">
                    <Button
                            style={{ width: "100%", marginTop: "10px" }}
                            variant="contained"
                            onClick={submitForm}
                        >
                            {searching ? (
                                <>
                                    <FontAwesomeIcon
                                        icon={faSpinner} spin
                                        style={{ marginRight: "0.5em" }}
                                    />
                                    Carregando...
                                </>
                            ) : (
                                resultReturned ? (
                                    <>
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            style={{ marginRight: "0.5em" }}
                                        />
                                        Salvo
                                    </>
                                ) : (
                                    <>
                                        <FontAwesomeIcon
                                            icon={faFloppyDisk}
                                            style={{ marginRight: "0.5em" }}
                                        />
                                        Salvar
                                    </>
                                )
                            )}

                        </Button>

                    </div>
                </form>
            </div>
        </div>
    );
};
export default ModalNovoUsuario;