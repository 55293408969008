import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { Api } from "../../../services/Api.js";

import "./ModalAlterarEixo.css";

const ModalAlterarEixo = ({ onClose, informacoesEixo }) => {
  const [numeroDeInicioDireita, setNumeroDeInicioDireita] = useState("");
  const [numeroDeFimDireita, setNumeroDeFimDireita] = useState("");
  const [numeroDeInicioEsquerda, setNumeroDeInicioEsquerda] = useState("");
  const [numeroDeFimEsquerda, setNumeroDeFimEsquerda] = useState("");
  const [postesIluminacao, setPostesIluminacao] = useState("");
  const [pavimentos, setPavimentos] = useState("");
  const [observacao, setObservacao] = useState("");

  // useEffect(() => {
  // }, [informacoesEixo] );

  const handleSubmit = (e) => {
    e.preventDefault();

    // API
    requestApiAlterarEixo();

    setNumeroDeInicioDireita("");
    setNumeroDeFimDireita("");
    setNumeroDeInicioEsquerda("");
    setNumeroDeFimEsquerda("");
    setPostesIluminacao("");
    setPavimentos("");
    setObservacao("");
  };

  const onCloseClick = () => {
    onClose();
  };

  //Para a chamada da função para sair
  const onModalClick = (e) => {
    e.stopPropagation();
  };

  async function testeAPI() {
    const data = await Api.eixosCadastrados(informacoesEixo.id);
    console.log(data);
  }

  async function requestApiAlterarEixo() {
    try {
      const infoAlterarEixo = {
        id: informacoesEixo.id,
        numInicioEsquerda: numeroDeInicioEsquerda,
        numFimEsquerda: numeroDeFimEsquerda,
        numInicioDireita: numeroDeInicioDireita,
        numFimDireita: numeroDeFimDireita,
        observacao: observacao,
        pavimento: pavimentos,
        postesIluminacao: postesIluminacao,
      };
      const data = await Api.addEixo(infoAlterarEixo);

      if (data.statusCode !== undefined) {
        alert("Preencha os campos corretamente");
      }
    } catch (err) {}
  }

  return (
    <div className="modal-Eixo" onClick={onCloseClick}>
      <div className="modal-content-Eixo" onClick={onModalClick}>
        <div className="modal-close-bnt">
          <Button
            aria-label="Fechar"
            style={{ padding: 0, minWidth: 0 }}
            onClick={onCloseClick}
          >
            <CloseIcon />
          </Button>
        </div>
        <h2
          style={{
            textAlign: "center",
            marginBottom: "10px",
            paddingTop: "10px",
          }}
        >
          Alterar Eixo
        </h2>

        <form onSubmit={handleSubmit}>
          <div className="form-group-Eixo" style={{ marginBottom: 25 }}>
            <input
              className="ModalAlterarEixo-fixed"
              id="eixo"
              value={informacoesEixo.logradouro.nome}
              required
              readOnly
            />
          </div>

          <div className="form-row-Eixo">
            <div style={{ width: "220px" }}>
              <label
                htmlFor="numeroDeInicioDireita"
                className="ModalAlterarEixo-label"
              >
                Número de início direita:
              </label>
              <input
                className="ModalAlterarEixo-input"
                type="text"
                id="numeroDeInicioDireita"
                value={numeroDeInicioDireita}
                onChange={(e) => setNumeroDeInicioDireita(e.target.value)}
                required
              />
            </div>
            <div style={{ width: "220px" }}>
              <label
                htmlFor="numeroDeInicioEsquerda"
                className="ModalAlterarEixo-label"
              >
                Número de início esquerda:
              </label>
              <input
                className="ModalAlterarEixo-input"
                type="text"
                id="ModalAlterarEixo-select"
                value={numeroDeInicioEsquerda}
                onChange={(e) => setNumeroDeInicioEsquerda(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="form-row-Eixo">
            <div style={{ width: "220px" }}>
              <label
                htmlFor="numeroDeFimDireita"
                className="ModalAlterarEixo-label"
              >
                Número de fim direita:
              </label>
              <input
                className="ModalAlterarEixo-input"
                type="text"
                id="ModalAlterarEixo-select"
                value={numeroDeFimDireita}
                onChange={(e) => setNumeroDeFimDireita(e.target.value)}
                required
              />
            </div>
            <div style={{ width: "220px" }}>
              <label
                htmlFor="numeroDeFimEsquerda"
                className="ModalAlterarEixo-label"
              >
                Número de fim esquerda:
              </label>
              <input
                className="ModalAlterarEixo-input"
                type="text"
                id="ModalAlterarEixo-select"
                value={numeroDeFimEsquerda}
                onChange={(e) => setNumeroDeFimEsquerda(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="form-row-Eixo">
            <div style={{ width: "220px" }}>
              <label htmlFor="selector" className="ModalAlterarEixo-label">
                Poste de iluminação:
              </label>
              <select
                className="ModalAlterarEixo-select"
                id="select"
                value={postesIluminacao}
                onChange={(e) => setPostesIluminacao(e.target.value)}
                required
              >
                <option value="">Selecione:</option>
                <option value="Quantidade Suficiente">
                  Quantidade Suficiente
                </option>
                <option value="Quantidade Insuficiente">
                  Quantidade Insuficiente
                </option>
                <option value="Inexistente">Inexistente</option>
              </select>
            </div>
            <div style={{ width: "220px" }}>
              <label htmlFor="selector" className="ModalAlterarEixo-label">
                Pavimentos:
              </label>
              <select
                className="ModalAlterarEixo-select"
                id="select"
                value={pavimentos}
                onChange={(e) => setPavimentos(e.target.value)}
                required
              >
                <option value="">Selecione:</option>
                <option value="Paralelepipedo">Paralelepípedo</option>
                <option value="Terra">Terra</option>
                <option value="Asfalto">Asfalto</option>
              </select>
            </div>
          </div>

          <div className="form-group-Eixo" style={{ marginTop: "40px" }}>
            <label htmlFor="observacao" className="ModalAlterarEixo-label">
              Observação:
            </label>
            <input
              className="ModalAlterarEixo-input"
              type="text"
              id="ModalAlterarEixo-select"
              value={observacao}
              onChange={(e) => setObservacao(e.target.value)}
              required
            />
          </div>

          <div className="form-row-Eixo">
            <Button
              style={{ width: "100%", marginTop: "10px" }}
              variant="contained"
            >
              <FontAwesomeIcon
                icon={faFloppyDisk}
                style={{ marginRight: "0.5em" }}
              />
              Salvar
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModalAlterarEixo;
