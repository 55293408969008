import { createContext, useState } from 'react';

const ContextMap = createContext(); //cria o contexto

//crie o provedor do contexto
const ContextMapProvider = ({ children }) => {
  // Inicializar lista de camadas
  const [listaCamadas, setListaCamadas] = useState([]);
  const [opcao, setOpcao] = useState('home');
  const [tipoMapa, setTipoMapa] = useState(true);

  const searchBuscaImovel = (zoom, coordenadasCentral, coordenadas, coordenadasCentralSemConversao) => {
    setObjMapa({
      ...objMapa,
      zoom: zoom,
      coordenadasCentral: coordenadasCentral,
      coordenadas: coordenadas,
      coordenadasCentralSemConversao: coordenadasCentralSemConversao
    });
  };
  const [objMapa, setObjMapa] = useState({
    zoom: '',
    coordenadasCentral: '',
    coordenadas: '',
    coordenadasCentralSemConversao: ''
  });

  const adicionarObjeto = (objeto) => {
    setListaCamadas([...listaCamadas, objeto]);
  };
  const removerObjeto = (id) => {
    const novaLista = listaCamadas.filter(objeto => objeto.id !== id);
    setListaCamadas(novaLista);
  };
  const alteraOpcao = (opcaoSelecionada) => {
    setOpcao(opcaoSelecionada);
  };

  const removerTodosObjetos = () => {
    setListaCamadas([]);
  }

  const alterarTipoMapa = (bool) => {
      setTipoMapa(bool)
  }

  return (
    <ContextMap.Provider value={{ listaCamadas, adicionarObjeto, removerObjeto, removerTodosObjetos, objMapa, searchBuscaImovel, opcao, alteraOpcao, tipoMapa, alterarTipoMapa }}>
      {children}
    </ContextMap.Provider>
  );

};
export { ContextMap, ContextMapProvider };

