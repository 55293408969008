import React, { useState } from "react";
import { Card, TableCell } from "@mui/material";
import { Table, TableContainer, Button, TableRow, TableBody, Paper } from '@mui/material';
import { Api } from "../../services/Api.js";

import "./ExportarDados.css";

const ExportarDados = () => {
    const [nome, setNome] = useState("");
    const [email, setEmail] = useState("");
    const [dataNascimento, setDataNascimento] = useState("");
    const [cpf, setCpf] = useState("");
    const [tipoUsuario, setTipoUsuario] = useState("");



    //API
    const chamadaApi = () => {
        const ApiInfos = {
            "name": nome,
            "email": email,
            "cpf": cpf,
            "dataNasc": dataNascimento,
            "role": "Administrador"
        }

        Api.ExportarDados(ApiInfos);
    }

    const handleNomeChange = (event) => {
        setNome(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleDataNascimentoChange = (event) => {
        setDataNascimento(event.target.value);
    };

    const handleCpfChange = (event) => {
        setCpf(event.target.value);
    };

    const handleTipoUsuarioChange = (event) => {
        setTipoUsuario(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        // Realizar ações com os dados do formulário
    };

    return (

        <div className="container-ExportarDados">
            <Card variant="outlined" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <TableContainer component={Paper} sx={{ height: '100%' }}>
                    <Table>
                        <TableRow style={{ borderBottom: '2.5px solid #1976D2', width: '100%' }}>
                            <TableCell style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <h2>
                                    Exportar Dados
                                </h2>
                            </TableCell>
                        </TableRow>
                        <TableContainer component={Paper} sx={{ height: '100%' }}>
                            <Table sx={{ minWidth: '100%' }} aria-label="caption table">
                                <TableBody>

                                    <TableRow>
                                        <TableCell align="left" sx={{ width: '90%' }}>Proprietários</TableCell>
                                        <TableCell >
                                            <Button sx={{ height: '35px', border: '1px solid #1976D2' }}>JSON</Button>
                                        </TableCell>
                                        <TableCell >
                                            <Button sx={{ height: '35px', border: '1px solid #1976D2' }}>CSV</Button>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell align="left" sx={{ width: '90%' }}>Logradouros</TableCell>
                                        <TableCell >
                                            <Button sx={{ height: '35px', border: '1px solid #1976D2' }}>JSON</Button>
                                        </TableCell>
                                        <TableCell >
                                            <Button sx={{ height: '35px', border: '1px solid #1976D2' }}>CSV</Button>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell align="left" sx={{ width: '90%' }}>Eixos</TableCell>
                                        <TableCell >
                                            <Button sx={{ height: '35px', border: '1px solid #1976D2' }}>JSON</Button>
                                        </TableCell>
                                        <TableCell >
                                            <Button sx={{ height: '35px', border: '1px solid #1976D2' }}>CSV</Button>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell align="left" sx={{ width: '90%' }}>Endereços</TableCell>
                                        <TableCell >
                                            <Button sx={{ height: '35px', border: '1px solid #1976D2' }}>JSON</Button>
                                        </TableCell>
                                        <TableCell >
                                            <Button sx={{ height: '35px', border: '1px solid #1976D2' }}>CSV</Button>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell align="left" sx={{ width: '90%' }}>Lotes</TableCell>
                                        <TableCell >
                                            <Button sx={{ height: '35px', border: '1px solid #1976D2' }}>JSON</Button>
                                        </TableCell>
                                        <TableCell >
                                            <Button sx={{ height: '35px', border: '1px solid #1976D2' }}>CSV</Button>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell align="left" sx={{ width: '90%' }}>Edificações</TableCell>
                                        <TableCell >
                                            <Button sx={{ height: '35px', border: '1px solid #1976D2' }}>JSON</Button>
                                        </TableCell>
                                        <TableCell >
                                            <Button sx={{ height: '35px', border: '1px solid #1976D2' }}>CSV</Button>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left" sx={{ width: '90%' }}>Pontos de Interesse</TableCell>
                                        <TableCell >
                                            <Button sx={{ height: '35px', border: '1px solid #1976D2' }}>JSON</Button>
                                        </TableCell>
                                        <TableCell >
                                            <Button sx={{ height: '35px', border: '1px solid #1976D2' }}>CSV</Button>
                                        </TableCell>
                                    </TableRow>

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Table>
                </TableContainer>
            </Card>
        </div >
    );
};

export default ExportarDados;
