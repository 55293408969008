import React, { useContext } from "react";
import MapRender from "../MapRender/MapRender";
import { ContextMap } from "../../ContextMap/ContextMap";
import FloatingButton from "../FloatingButton/FloatingButton";
import AlterarProprietario from "../AlterarProprietario/AlterarProprietario";
import AlterarEixo from "../AlterarEixo/AlterarEixo";
import AlterarImovel from "../AlterarImovel/AlterarImovel";
import AlterarLogradouro from "../AlterarLogradouro/AlterarLogradouro";
import GerenciarAdversidades from "../GerenciarAdversidades/GerenciarAdversidades";
import GerenciarUsuarios from "../GerenciaUsuarios/GerenciarUsuarios";
import AlterarPerfil from "../AlterarPerfil/AlterarPerfil";
import ImprimirBic from "../ImprimirBic/ImprimirBic";
import GerenciarColetas from "../GerenciarColetas/GerenciarColetas";
import ExportarDados from "../ExportarDados/ExportarDados";


function Conteudo(estadoLogin) {
    const { opcao } = useContext(ContextMap);

    function renderizarCampoDeEntrada() {
        let opcaoControle = estadoLogin.estadoLogin === '' ? 'home' : opcao;
        switch (opcaoControle) {
            case 'home':
                return (
                    <div>
                        <MapRender />
                        <FloatingButton />
                    </div>
                );
            case 'alterarImovel':
                return (
                    <div>
                        <AlterarImovel />
                    </div>
                );
            case 'alterarProprietario':
                return (
                    // <h1>alterarProprietario</h1>
                    <div>
                        <AlterarProprietario />
                    </div>
                );
            case 'alterarEixo':
                return (
                    // <h1>alterarEixo</h1>
                    <div>
                        <AlterarEixo />
                    </div>
                );
            case 'alterarLogradouro':
                return (
                    // <h1>alterarLogradouro</h1>
                    <div>
                        <AlterarLogradouro />
                    </div>
                );
            case 'gerenciarAdversidades':
                return (
                    // <h1>gerenciarAdversidades</h1>
                    <div>
                        <GerenciarAdversidades />
                    </div>
                );
            case 'gerenciarUsuarios':
                return (
                    <div>
                        <GerenciarUsuarios />
                    </div>
                );

            case 'alterarPerfil':
                return (
                    <div>
                        <AlterarPerfil />
                    </div>
                );

            case 'imprimirBic':
                return (
                    <div>
                        <ImprimirBic />
                    </div>
                );
            case 'gerenciarColetas':
                return (
                    <div>
                        <GerenciarColetas />
                    </div>
                );
            case 'exportarDados':
                return (
                    <div>
                        <ExportarDados />
                    </div>
                );

            default:
                return null;
        }
    }
    return (
        <div style={{ position: "relative", width: '100%', height: '91vh', margin: '0 auto', backgroundColor: " rgb(245, 248, 255)", zIndex: '4' }}>
            {renderizarCampoDeEntrada()}
        </div>
    );
}

export default Conteudo;