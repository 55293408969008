import React, { useState, } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faHouse } from "@fortawesome/free-solid-svg-icons";
import { Button, } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import "./ModalInfo.css";
import TabsInfo from "./TabsInfo/TabsInfo";

export default function ModalInfo({ onClose, infoImovel }) {
  const onCloseClick = () => {
    onClose();
  };
  const onModalClick = (e) => {
    e.stopPropagation();
  };

  const [escolha, setEscolha] = useState(null);
 
  return (
    <div className="ModalInfo-modal" onClick={onCloseClick}>

      <div className="ModalInfo-modal-content" onClick={onModalClick}>

        <div className="ModalInfo-row " >
          <div className="ModalInfo-row-group">
            <FontAwesomeIcon icon={faHouse} color="#3f50b5" fontSize='12px' />
            <label className="ModalInfo-text">Imóvel</label>
          </div>
          <Button aria-label="Fechar" onClick={onCloseClick}>
            <CloseIcon fontSize="12px" />
          </Button>
        </div>

        {infoImovel.imoveis.length === 1 ? (
          <TabsInfo indice={0} infoImovel={infoImovel} />
        ) : (
          escolha !== null ? (
            <TabsInfo indice={escolha} infoImovel={infoImovel} />
          ) : (
            <>
              <div className="ModalInfo-box ModalInfo-scrollbar ModalInfo-linha ModalInfo-imoveis">

                <div className="ModalInfo-form-row">
                  <div className="ModalInfo-form-group ModalInfo-row-column" style={{ marginBottom: 0 }}>
                    <label className="ModalInfo-label ">
                      Logradouro
                    </label>
                  </div>
                  <div className="ModalInfo-form-group" style={{ marginBottom: 0 }}>
                    <label className="ModalInfo-label">
                      Número
                    </label>
                  </div>
                  <div className="ModalInfo-form-group" style={{ marginBottom: 0 }}>
                    <label className="ModalInfo-label">
                      Complemento
                    </label>
                  </div>
                  <div className="ModalInfo-form-group" style={{ marginBottom: 0 }} />
                </div>
                <div className="ModalInfo-linhaAzul" />

                {infoImovel.imoveis.map((imoveis, index) => (
                  <div key={index} className="ModalInfo-form-row">
                    <div className="ModalInfo-form-group" style={{ marginTop: '5px', width: '37%' }}>
                      <label className="ModalInfo-label-text">
                        {imoveis.logradouro.nome}
                      </label>
                    </div>
                    <div className="ModalInfo-form-group" style={{ marginTop: '5px' }}>
                      <label className="ModalInfo-label-text">
                        {imoveis.endereco.numero}
                      </label>
                    </div>
                    <div className="ModalInfo-form-group" style={{ marginTop: '5px' }}>
                      <label className="ModalInfo-label-text">
                        {/* {imoveis.complemento} */}
                      </label>
                    </div>
                    <div className="ModalInfo-form-group" style={{ marginTop: '5px' }}>
                      <Button variant="outlined" style={{ minWidth: '30px' }} onClick={() => setEscolha(index)}  >
                        <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '12px' }} />
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )
        )}
      </div>
    </div >
  );
};

