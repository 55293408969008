const url = process.env.REACT_APP_API;

export const Api = {
  async loginPost(infoLogin) {
    try {
      const response = await fetch(url + "/auth", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
        },
        body: JSON.stringify(infoLogin),
      });

      const data = await response.json();

      sessionStorage.setItem("infoUsuarioEmail", data.email);
      sessionStorage.setItem("infoUsuarioToken", data.token);
      return data;
    } catch (error) {
      throw error;
    }
  },

  async numeroInscricaoPost(objeto) {
    try {
      const response = await fetch(url + "/imoveis/info", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
        },
        body: JSON.stringify(objeto),
      });
      const data = await response.json();

      return data;
    } catch (error) {
      throw error;
    }
  },

  async imoveisSavePost(objeto) {
    try {
      const response = await fetch(url + "/imoveis/save", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
        },
        body: JSON.stringify(objeto),
      });
      const data = await response.json();

      return data;
    } catch (error) {
      throw error;
    }
  },

  async showPontoInteresse(objeto) {
    try {
      const response = await fetch(url + "/imoveis/pontoInteresse/show", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
        },
        body: JSON.stringify(objeto),
      });

      const data = await response.json();

      return data;
    } catch (error) {
      throw error;
    }
  },

  async createPontoInteresse(objeto) {
    try {
      const response = await fetch(url + "/imoveis/pontoInteresse/create", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
        },
        body: JSON.stringify(objeto),
      });

      const data = await response.json();

      return data;
    } catch (error) {
      throw error;
    }
  },

  async updatePontoInteresse(objeto) {
    try {
      const response = await fetch(url + "/imoveis/pontoInteresse/", {
        method: "PATCH",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
        },
        body: JSON.stringify(objeto),
      });

      const data = await response.json();

      return data;
    } catch (error) {
      throw error;
    }
  },

  async getPontoInteresse(objeto) {
    try {
      const response = await fetch(
        url + "/imoveis/pontoInteresse?page=0&limit=0" + objeto,
        {
          method: "GET",
          headers: {
            Authorization:
              "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
            "Content-Type": "application/json",
            "access-control-allow-origin": "*",
          },
          body: JSON.stringify(),
        }
      );
      const data = await response.json();

      localStorage.setItem("infoTotalPontoInteresse", data.totalItems);
      return data;
    } catch (error) {
      return error;
    }
  },

  async deletePontoInteresse(id) {
    try {
      const response = await fetch(url + "/imoveis/pontoInteresse/" + id, {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
        },
        body: JSON.stringify(),
      });

      const data = await response.json();

      return data;
    } catch (error) {
      throw error;
    }
  },

  async proprietariosCadastrados(totalProprietarios) {
    try {
      const response = await fetch(
        url + "/proprietarios?page=0&limit=0" + totalProprietarios,
        {
          method: "GET",
          headers: {
            Authorization:
              "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          },
          body: JSON.stringify(),
        }
      );

      const data = await response.json();

      localStorage.setItem("infoTotalProprietarios", data.meta.totalItems);
      return data;
    } catch (error) {
      throw error;
    }
  },

  async addProprietarios(infoCadastro) {
    try {
      const response = await fetch(url + "/proprietarios/create", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
        },
        body: JSON.stringify(infoCadastro),
      });

      const data = await response.json();

      return data;
    } catch (error) {
      return error;
    }
  },

  async searchEixo(nomeRua) {
    try {
      const response = await fetch(url + "/eixos/search", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
        },
        body: JSON.stringify({
          logradouro: nomeRua,
        }),
      });

      const data = await response.json();

      return data;
    } catch (error) {
      return error;
    }
  },

  async eixosCadastrados(idEixos) {
    try {
      const response = await fetch(url + "/eixos/" + idEixos, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(),
      });

      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  },

  async addEixo(infoEixo) {
    try {
      const response = await fetch(url + "/eixos/save", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
        },
        body: JSON.stringify(infoEixo),
      });

      const data = await response.json();

      return data;
    } catch (error) {
      return error;
    }
  },

  async searchLogradouro(nomeRua) {
    try {
      const response = await fetch(url + "/logradouros/search", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
        },
        body: JSON.stringify({
          logradouro: nomeRua,
        }),
      });

      const data = await response.json();

      return data;
    } catch (error) {
      return error;
    }
  },

  async logradourosCadastrados(idLogradouro) {
    try {
      const response = await fetch(url + "/logradouros/" + idLogradouro, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
        },
        body: JSON.stringify(),
      });

      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  },

  async addLogradouro(infoLogradouro) {
    try {
      const response = await fetch(url + "/logradouros/save", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
        },
        body: JSON.stringify(infoLogradouro),
      });

      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },

  async searchInscricaoReduzida(objeto) {
    try {
      const response = await fetch(url + "/map/cod", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
        },
        body: JSON.stringify(objeto),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },

  async searchNumInscricao(objeto) {
    try {
      const response = await fetch(url + "/map/inscr", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
        },
        body: JSON.stringify(objeto),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },

  async searchEndereco(objeto) {
    try {
      const response = await fetch(url + "/map/endereco", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
        },
        body: JSON.stringify(objeto),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },
  async getUsers(objeto) {
    try {
      const response = await fetch(
        url + "/users/search?page=0&limit=0" + objeto,
        {
          method: "POST",
          headers: {
            Authorization:
              "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
            "Content-Type": "application/json",
            "access-control-allow-origin": "*",
          },
          body: JSON.stringify(),
        }
      );
      const data = await response.json();
      localStorage.setItem("infoTotalUsuarios", data.meta.totalItems);
      return data;
    } catch (error) {
      return error;
    }
  },
  async getUsersId(id) {
    try {
      const response = await fetch(url + "/users/" + id, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },
  async newUsers(objeto) {
    try {
      const response = await fetch(url + "/users", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
        },
        body: JSON.stringify(objeto),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },
  async editUsers(objeto) {
    try {
      const response = await fetch(url + "/users", {
        method: "PATCH",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
        },
        body: JSON.stringify(objeto),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },

  async deleteUsers(id) {
    try {
      const response = await fetch(url + "/users/" + id, {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
        },
        body: JSON.stringify(),
      });

      return response;
    } catch (error) {
      throw error;
    }
  },

  async getAlterarPerfil() {
    try {
      const response = await fetch(url + "/users", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },

  async alterarPerfilRequest(objeto) {
    try {
      const response = await fetch(url + "/users", {
        method: "PATCH",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
        },
        body: JSON.stringify(objeto),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },

  async getAdversidades() {
    try {
      const response = await fetch(url + "/adversidades", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
        },
        body: JSON.stringify(),
      });

      const data = await response.json();
      console.log(data);
      return data;
    } catch (error) {
      throw error;
    }
  },

  async getAdversidadesId(id) {
    try {
      const response = await fetch(url + "/adversidades/" + id, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
        },
        body: JSON.stringify(),
      });
      const data = await response.json();
      console.log(data);
      return data;
    } catch (error) {
      throw error;
    }
  },

  async getCoord(objeto) {
    try {
      const response = await fetch(url + "/map/coord", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
        },
        body: JSON.stringify(objeto),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },
};
